.section-justice {
	z-index: z('default', 2);
	padding-bottom: 30px;
	
	@media #{md('medium')} {
		padding-bottom: 80px;
	}

	&:before {
		content:'';
		position: absolute;
		left: 50%;
		width: 1px;
		top: -20px;
		bottom: -200px;
		background: #cacac9;
		display: none;

		@media #{md('medium')} {
			display: block;
		}
	}
	big {
		font-size: 1.33em;
		line-height: 1.4em;
	}

	p {
		margin: 1.5em 0;
	}

	h2 {
		margin-bottom: 0.5em;
	}
}

.block-justice{
	width: 100%;
	background: $grey-light;
	margin-top: 30px;
	margin-bottom: 10px;

	@media #{md('medium')} {
		margin-top: 80px;
		margin-bottom: 80px;
	}

	&:before {
		top: 85px;
	}

	.block-img {
		width: 100%;
		height: 175px;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
	}

	.block-content {

		& > * {
			&:nth-child(2) {
				color: $grey;
			}
		}
	}
}

.section-organisation-justice {
	z-index: z('default');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right bottom;
	width: 100%;


	.block-news:not(.teasers),
	.bg-news {
		@media #{md('medium')} {
			transform: translateY(-100px);
			margin-bottom: -100px;
		}
	}

	.block-content {
		width: 80%;
		margin: auto;
		margin-top: 80px;

		@media #{md('medium')} {
			margin-top: 240px;
		}

		h2 {
			.icon {
				display: block;
				margin: auto;

				* {
					fill: $red;
				}
			}
		}

		p {
			margin-bottom: 3em;
		}
	}

	.teasers {
		@media #{md('medium')} {
			bottom: auto;
			top: 65%;
		}
	}

	.block-dl-diagramme {
		background: transparent url(../img/bg-orga-justice.jpg) center center no-repeat;
		background-size: cover;

		@media #{md('medium')} {
			background: none;
		}

	}
}

.justice {
	.section-infos {
		padding-top: 40px;

		@media #{md('medium')} {
			padding-top: 200px;
		}
	}
}