.pagination {
	margin-top: 3em;
	margin-bottom: 3em;
	text-align: center;
	line-height: 2.3em;
	font-size: 0.87em;

	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			display: inline-block;

			a {
				margin: 0 3px;
				text-transform: uppercase;
				font-weight: 700;
				border: 1px solid $grey-light;
				padding: 0 10px;
				display: block;
				color: $grey;
				overflow: hidden;
				transform: translateZ(0);
				will-change: color;
				transition: all 0.8s $out-quint;

				.icon {
					margin-right: 5px;
					top: -1px;

					* {
						fill: $grey;
						transition: all 0.4s ease;
					}
				}

				&.next {
					color: $red;

					.icon {
						margin-right: 0;
						margin-left: 5px;

						* {
							fill: $red;
						}
					}

					&:before {
						background: $red;
					}
				}

				&:before {
					content:'';
					transform: skewY(-15deg) translateY(150%) scaleX(0.6) translateZ(0);
					width: 100%;
					height: 200%;
					background: $grey;
					position: absolute;
					top: -50%;
					left: 0;
					will-change: transform;
					transition: all 0.9s $out-quint;
				}

				&:hover {
					color: #FFF !important;

					&:before {
						transform: skewY(-10deg) translateY(0%) scaleX(1.25) translateZ(0);
					}

					.icon {
						* {
							fill: #FFF !important;
						}
					}
				}
			}
		}
	}

	.pagination-conf {
		padding: 15px 0;

		.select {
			display: inline-block;
			margin-right: 10px;
			cursor: pointer;

			&:after {
				content:'';
				width: 14px;
				height: 7px;
				position: absolute;
				background: transparent url(#{$svg-dir}chevron.svg) center center no-repeat;
				background-size: contain;
				top: 50%;
				right: 10px;
				margin-top: -3px;
				pointer-events: none;
				transition: all 0.4s ease;
			}

			select {
				appearance: none;
				border-radius: none;
				padding: 0;
				border: 1px solid #cfcfcf;
				background: $grey-light;
				padding-right: 35px;
				cursor: pointer;
				height: 32px;
				padding-left: 10px;
				border-radius: 0;
			}

			&:hover {
				&:after {
					transform: rotate(180deg);
				}
			}
		}
	}
}