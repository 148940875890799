/*---------------------------------------*\
		Positions & dispositions
\*---------------------------------------*/

@mixin clearfix {

	&:after {
		content: "";
		clear: both;
		display: block;
	}
}



/*---------------------------------------*\
		Typographie
\*---------------------------------------*/

@mixin fontfaces($webfonts, $dir) {

	@each $webfont in $webfonts {

		@font-face {
			font-family: nth($webfont, 1);
			src: url("#{$dir}#{nth($webfont, 2)}.woff2") format("woff2"),
					 url("#{$dir}#{nth($webfont, 2)}.woff") format("woff"),
					 url("#{$dir}#{nth($webfont, 2)}.ttf") format("truetype");
			font-weight: #{nth($webfont, 3)};
			font-style: #{nth($webfont, 4)};
		}
	}
}

@mixin reset-ul {
	margin: 0;
	padding-left: 0;
	list-style: none;
}

@mixin ellipsis {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

@mixin antialiased {
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-moz-osx-font-smoothing: antialiased;
}

// Nicely hyphenate long words
// cf. https://justmarkup.com/log/2015/07/31/dealing-with-long-words-in-css/
@mixin hyphenate {
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
}




/*---------------------------------------*\
		Triangles
\*---------------------------------------*/

@mixin triangle-up($triangle-up-width, $triangle-up-height, $triangle-up-color) {
	width: 0;
	height: 0;
	border-color: transparent transparent $triangle-up-color;
	border-style: solid;
	border-width: 0 $triangle-up-width / 2 $triangle-up-height;
}

@mixin triangle-right($triangle-right-width, $triangle-right-height, $triangle-right-color) {
	width: 0;
	height: 0;
	border-color: transparent transparent transparent $triangle-right-color;
	border-style: solid;
	border-width: $triangle-right-height / 2 0 $triangle-right-height / 2 $triangle-right-width;
}

@mixin triangle-down($triangle-down-width, $triangle-down-height, $triangle-down-color) {
	width: 0;
	height: 0;
	border-color: $triangle-down-color transparent transparent;
	border-style: solid;
	border-width: $triangle-down-height $triangle-down-width / 2 0;
}

@mixin triangle-left($triangle-left-width, $triangle-left-height, $triangle-left-color) {
	width: 0;
	height: 0;
	border-color: transparent $triangle-left-color transparent transparent;
	border-style: solid;
	border-width: $triangle-left-height / 2 $triangle-left-widt $triangle-left-height / 2 0;
}





/*---------------------------------------*\
		SVG Responsive
\*---------------------------------------*/

/*
Utilitaire pour le padding hack qui permet d'avoir des fichiers `*.svg` responsive.
Plus d'infos ici : http://tympanus.net/codrops/2014/08/19/making-svgs-responsive-with-css/
*/

@mixin padding-hack($svg-width, $svg-height, $container-width) {
	height: 0;
	width: $container-width;
	padding-top: strip-units(($svg-height / $svg-width) * $container-width) * 1%;
}





/*------------------------------------*\
		Gradients
\*------------------------------------*/

/**
 * Mixin printing a linear-gradient
 * as well as a plain color fallback
 * and the `-webkit-` prefixed declaration
 * @param {String | List | Angle} $direction - Linear gradient direction
 * @param {Arglist} $color-stops - List of color-stops composing the gradient
 */
@mixin linear-gradient($direction, $color-stops...) {
	@if is-direction($direction) == false {
		$color-stops: ($direction, $color-stops);
		$direction: 180deg;
	}

	background: nth(nth($color-stops, 1), 1);
	background: linear-gradient($direction, $color-stops);
}



/*------------------------------------*\
		Hover img
\*------------------------------------*/
@mixin hover-img() {
	&:after {
		position: absolute;
		top: 0;
		left: 0;
		content:'';
		width: 100%;
		height: 100%;
		content:'';
		background: rgba($grey-dark, 0.7);
		opacity: 0;
		transition: all 0.4s ease;
	}

	span {
		font-size: 0.86em;
		text-transform: uppercase;
		text-align: center;
		display: block;
		font-weight: 700;
		color: #FFF;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		letter-spacing: 0.1em;
		z-index: z('default', 1);
		opacity: 0;
		margin-top: 10px;
		transition: all 0.4s ease 0.1s;
	}

	.icon {
		position: absolute;
		left: 50%;
		margin-left: -18px;
		top: 50%;
		margin-top: 20px;
		z-index: z('default', 1);
		opacity: 0;
		transform: translateX(-10px);
		transition: all 0.4s $in-out-expo;
	}

	&:hover {
		&:after {
			opacity: 1;
		}

		span {
			opacity: 1;
			margin-top: 0;
			transition-delay: 0;
		}

		.icon {
			opacity: 1;
			transform: translateX(0);
			transition-delay: 0.15s;
		}
	}
}


/*------------------------------------*\
		Links
\*------------------------------------*/
@mixin links($color) {
	font-weight: 700;
	text-transform: uppercase;
	font-size: 0.86em;
	display: inline-block;
	letter-spacing: 0.1em;

	.icon {
		transition: all 0.3s $in-out-expo;
		margin-left: 10px;
		top: -2px;

		* {
			transition: all 0.3s ease;
		}
	}

	&:hover {
		color: $color;

		.icon {
			transform: translateX(10px);

			* {
				fill: $color;
			}
		}
	}
}