.section-header-guichet{
	padding-top: 80px;
	padding-bottom: 30px;
	background: $grey-light;
	margin-top: -60px;

	@media #{md('small')} {
		margin-top: -87px;
		padding-top: 140px;
		padding-bottom: 90px;
	}

	big {
		margin-bottom: 30px;
		line-height: 1.4em;

		@media #{md('medium')} {
			line-height: 50px;
		}
	}
}

.guichet-search {
	margin-bottom: 20px;

	&.theme-grey {
		input {
			background: $grey-light;
		}
	}

	input {
		display: block;
		height: 50px;
		line-height: 50px;
		background: #FFF;
		color: $grey;
		padding: 0 15px;
		font-size: 1.2em;
		width: 100%;

		&::-webkit-input-placeholder { color: $grey; opacity: 1; }
		&::-moz-placeholder { color: $grey; opacity: 1; }
		&:-ms-input-placeholder { color: $grey; opacity: 1; }
		&:-moz-placeholder { color: $grey; opacity: 1; }
	}

	button {
		display: block;
		position: absolute;
		background: $red;
		border: none;
		height: 50px;
		width: 50px;
		line-height: 50px;
		cursor: pointer;
		top: 0;
		right: 0;
		transition: all 0.4s ease;

		.icon {
			* {
				fill: #FFF;
			}
		}

		&:hover {
			opacity: 0.7;
		}
	}
}

.guichet-favorite{
	text-align: center;
	margin-bottom: 15px;

	&:hover {
		.favorite-info {
			background: rgba(#FFF, 0.5);
		}
		.favorite-img{
			&:after {
				opacity: 0.7;
			}

			img{
				transform: scale(1.1) translateZ(0);
			}
		}
	}

	.favorite-img {
		width: 100%;
		min-height: 70px;
		overflow: hidden;

		img {
			display: block;
			transform: scale(1) translateZ(0);
			transition: all 0.4s ease-out;

			&.is-loaded {
				width: 100%;
			}
		}

		&:after {
			content:'';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: $grey-dark;
			opacity: 0;
			transition: all 0.3s ease-out;
		}
	}

	.favorite-info{
		height: 120px;
		background: #FFF;
		transition: all 0.4s ease;
		width: 100%;
			
		@media #{md('small')} {
			height: 140px;
		}

		.u-cell {
			vertical-align: middle;
		}

		.favorite-theme {
			color: $red;
			text-transform: uppercase;
			display: block;
			line-height: 1.2em;
			padding-bottom: 5px;
		}

		.favorite-title {
			color: $grey-dark;
			font-size: 1.2em;
			line-height: 1.2em;

			@media #{md('small')} {
				font-size: 1.53em;
			}
		}
	}
}

.section-tree-of-life {
	background: $grey-light;
	padding-top: 20px;
		
	@media #{md('medium')} {
		padding-top: 40px;
	}

	.list-tree-of-life {
		width: 100%;
		display: table;
		padding-top: 0px;
		padding-bottom: 30px;
		table-layout: fixed;

		&:after {
			content:'';
			display: table;
			clear:both;
		}

		@media #{md('medium')} {
			padding-top: 50px;
			padding-bottom: 60px;

			&:after {
				display: none;
			}
		}
	}
}

.tree-of-life-item {
	text-align: center;
	display: block;
	float: left;
	width: 50%;
	color: $grey;
	transition: all 0.4s ease;
	font-size: 1.47em;
	vertical-align: middle;
	padding-top: 20px;
	padding-bottom: 20px;

	@media #{md('medium')} {
		display: table-cell;
		float: none;
		width: auto;
	}

	.tree-of-life-icon {
		display: block;
		width: 100%;
		margin-bottom: 30px;
		height: 130px;

		.icon {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);

			* {
				transition: all 0.4s ease;
			}
		}
	}

	&:hover {
		color: $grey-dark;

		.icon {
			* {
				fill: $grey-dark;
			}
		}
	}
}

.list-guichet{
	list-style: none;
	padding: 0;
	margin-top: 2em;
	margin-bottom: 2em;
	border-top: 2px solid $grey-light;

	@media #{md('medium')} {
		margin-top: 2.2em;
		margin-bottom: 3.5em;
	}

	li {
		display: block;
		border-bottom: 2px solid $grey-light;
	}
}

.guichet-item{
	font-size: 1.04em;
	display: block;
	padding: 0.9em 0;
	line-height: 1.125em;
	
	&:after {
		content:'';
		clear: both;
		display: table;
	}

	&:before {
		content:'';
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		background: $grey-light;
		transform: scaleX(0);
		transform-origin: left center;
		transition: all 0.4s $in-out-expo;
	}

	&:hover {
		.guichet-name {
			//font-weight: 700;
		}

		&:before {
			transform: scaleX(1);
		}

		.icon {
			opacity: 1;
			transform: translateX(0);
			transition-delay: 0.15s;
		}
	}

	.guichet-name {
		color: $red;
	}

	.guichet-desc {
		color: $grey;
		font-style: italic;
	}

	.icon {
		position: absolute;
		top: 50%;
		margin-top: -6px;
		right: 15px;
		opacity: 0;
		transform: translateX(-20px);
		transition: all 0.4s $in-out-expo;
		display: none;

		@media #{md('small')} {
			display: block;
		}

		* {
			fill: $grey-dark;
		}
	}
}