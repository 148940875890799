/*------------------------------------*\
		Cookies
\*------------------------------------*/

.cookies {
	z-index: z("modal");
	position: fixed;
	bottom: 0;
	left: 0;
	display: none;
	width: 100%;

	visibility: hidden;

	&.is-visible {
		display: block;
		visibility: visible;
	}
}

// .cookies__text {}
// .cookies__btn {}