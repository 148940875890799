.filter {
	margin-bottom: 2.5em;

	h3 {
		text-transform: uppercase;
		font-size: 1.04em;
		font-weight: 700;
		margin-bottom: 0.6em;
		color: $grey;
	}

	input[type="checkbox"] {
		display: none;

		&:checked {
			& + label {

				&:before {
					background: $red;
					border-color: $red;
				}

				&:after {
					transform: scale(1);
				}
			}
		}

		& + label {
			cursor: pointer;
			transition: all 0.4s ease;
			color: $grey-dark;
			margin: 5px 0;
			display: block;
			line-height: 1.4em;

			&:hover {
				opacity: 0.7;
			}

			&:before {
				content:'';
				width: 17px;
				height: 16px;
				display: inline-block;
				vertical-align: middle;
				margin-right: 8px;
				border: 1px solid $grey;
				transition: all 0.3s ease;
				top: -2px;
			}

			&:after {
				content:'';
				width: 11px;
				height: 8px;
				position: absolute;
				top: 50%;
				margin-top: -4px;
				left: 3px;
				background: transparent url(#{$svg-dir}check.svg) center center no-repeat;
				transition: all 0.3s ease;
				transform: scale(0);
			}
		}
	}

	.field-date {
		line-height: 30px;
		border: 1px solid #cfcfcf;
		padding: 0 12px;
		display: inline-block;
		white-space: nowrap;
		margin: 5px 0;

		label {
			display: inline-block;
			color: $grey-dark;
		}

		input {
			display: inline-block;
			border-radius: none;
			border: 0;
			height: 30px;
			line-height: 30px;
			appearance: none;
			padding: 0 14px; 
			color: $grey;
		}

		.trigger-datepopup {
			marign-left: 5px;
			display: inline-block;

			&:hover {
				opacity: 0.5;
			}
		}

		.icon_calendar,
		.svg_calendar {
			width: 16px;
			height: 14px;
		}
	}

	.form-action {
		margin: 5px 0;

		button[type="submit"] {
			padding: 7px 10px;

			.icon {
				margin-left: 4px;
			}
		}
	}
}