.section-bourse-jobs {
	.list-jobs {
		margin-top: 25px;
		margin-bottom: 40px;

		@media #{md('medium')} {
			margin-top: 50px;
			margin-bottom: 90px;
		}
	}

	.job-item {
		display: block;

		.job-content {
			background-color: $grey-light;
		}

		&:hover {
			.job-content {
				background-color: rgba($grey-light, 0.4);
			}
		}
	}
}