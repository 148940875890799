.form-search {
	margin: 0 auto;
	margin-top: 2em;
	margin-bottom: 2em;
	width: 100%;

	@media #{md('medium')} {
		width: 70%;
	}

	.home & {
		width: auto;
		margin-top: 0;
		margin-left: 7px;
		margin-right: 7px;
	}

	.icon {
		top: 50%;
		position: absolute;
		right: 0;
		margin: 0 15px;
		transform: translateY(-50%);
		z-index: z('default');
		pointer-events: none;

		* {
			fill: #FFF;
			
			@media #{md('small')} {
				fill: $grey;
			}
		}
		
		@media #{md('small')} {
			left: 0;
			right: auto;
		}
	}
	input {
		display: block;
		width: 100%;
		height: 50px;
		line-height: 50px;
		border: none;
		background: $grey-light;
		padding: 0 10px;
		padding-right: 60px;
		
		@media #{md('small')} {
			padding-left: 50px;
			padding-right: 150px;
		}
	}

	button {
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		line-height: 50px;
		background: $red;
		border: none;
		cursor: pointer;
		color: #FFF;
		font-weight: 700;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		transition: all 0.4s ease;
		width: 50px;
		font-size: 0;

		@media #{md('small')} {
			padding: 0 24px;
			font-size: 0.86em; 
			width: auto;
		}

		&:hover {
			opacity: 0.7;
		}
	}
}

.section-search {
	margin-top: 20px;

	@media #{md('small')} {
		margin-top: 50px;
	}

	.form-search {
		width: 100%;
		margin-bottom: 30px;

		@media #{md('small')} {
			margin-bottom: 60px;
		}
	}
}

.search-item {
	border-bottom: 2px solid $grey-light;

	&:first-child {
		border-top: 2px solid $grey-light;
	}

	a {
		display: block;
		padding: 15px;
		display: block;

		@media #{md('small')} {
			padding-right: 22%;
		}

		&:before {
			background: $grey-light;
			content:'';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			transition: all 0.6s $in-out-expo;
			transform-origin: left center;
			transform: scaleX(0);
		}

		&:hover {
			&:before {
				transform: scaleX(1);
			}
		}
	}

	.search-info {
		display: block;
		margin-bottom: 5px;
		color: $grey;
	}

	.search-title {
		font-weight: 700;
		display: block;
	}

	.search-cat {
		display: block;
		margin-top: 12px;
		color: $grey;
		text-transform: uppercase;
		font-size: 0.93em;

		@media #{md('small')} {
			position: absolute;
			top: 50%;
			right: 15px;
			width: 17%;
			transform: translateY(-50%);
			margin-top: 0;
		}
	}
}