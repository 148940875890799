.bg-news {
	left: 0;
	right: 70%;
	position: absolute;
	top: 0;
	bottom: -100px;
	background: $grey-dark;
	display: none;

	@media #{md("medium")} {
		display: block;
		// parallax
		//transform: translateY(-100px);
	}
	@media #{md("larger")} {
		right: 65%;
	}
	@media #{md("largest")} {
		right: 61%;
	}
}
.block-news {
	padding-top: 15px;
	background: $grey-dark;

	@media #{md('medium')} {
		padding-top: 30px;
		background: none;
	}

	.block-title {
		color: #FFF;
		margin-bottom: 1.5em;
	}

	.communication-item {
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid rgba(#FFF, 0.1);

		@media #{md('medium')} {
			margin-bottom: 30px;
			padding-bottom: 30px;
		}

		.communication-cat,
		.communication-title {
			height: auto;
		}
		.communication-title {
			margin-bottom: 1em;
			color: #FFF;
		}
	}

	&.teasers {
		background: $red;
		padding: 5px 20px;
		padding-bottom: 30px;
		color: #FFF;

		@media #{md('medium')} {
			position: absolute; 
			top: -40px;
			bottom: -40px;
			right: 0;
			// parallax
			//transform: translateY(-70px);
		}

		.news-desc {
			display: none;

			@media #{md('small')} {
				display: block;
			}
		}

		.block-title {
			color: $grey-dark;
			margin-bottom: 0.5em;

			&:before {
				background: $grey-dark;
			}
		}
	}
}

.news-item {
	.news-title {
		font-size: 1.53em;
		margin-top: 0.4em;
		margin-bottom: 1.5em;

		a {
			display: block;

			&:hover {
				opacity: 0.6;
			}
		}
	}

	.news-desc {
		//font-family: $font-family-serif;
		margin-bottom: 2em;
		line-height: 1.53em;
	}

	.news-date {
		color: $grey-dark;
		font-weight: 700;
	}

	.news-link {
		@include links($grey-dark);
	}
}

.single {
	padding-bottom: 50px;

	.single-actions {
		line-height: 50px;
		font-weight: 700;
		text-transform: uppercase;
		top: -10px;
		z-index: z('default', 1);
		border-bottom: 1px solid #cacac9;
		margin: 0 7px;
		padding-left: 10px;
		font-size: 0.87em;

		@media #{md('small')} {
			top: -20px;
		}

		@media #{md('medium')} {
			position: absolute;
			bottom: 100%;
			right: 0;
			width: auto;
			top: auto;
			line-height: 65px;
			padding-left: 0;
			margin: 0;
			margin-bottom: 20px;
			border-bottom: 0;
		}

		.link {
			display: inline-block;
		}

		.trigger-share {
			display: inline-block;
			border-left:1px solid #cacac9;
			padding-left: 20px;
			padding-right: 20px;
			float: right;
			margin-left: 10px;
		
			@media #{md('medium')} {
				float: none;
				padding-left: 20px;
				margin-left: 20px;
				padding-right: 30px;
			}

			.icon {
				transition: all 0.3s ease;
			}

			&:hover {
				.icon {
					opacity: 0.7;
					//transform: scaleY(0.8);
				}
			}
		}
	}

	.single-content-type {
		line-height: 2.3em;
		letter-spacing: 0.02em;
		display: inline-block;
		padding: 0 10px;
		margin-top: 20px;
		background: $red;
		text-transform: uppercase;
		color: #FFF;
		font-size: 0.87em;
		font-weight: 700;

		@media #{md('medium')} {
			margin-top: 40px;
		}
	}

	.single-meta {
		font-size: 1.1em;
		color: $grey;
		margin-bottom: 20px;
		
		@media #{md('medium')} {
			margin-top: -10px;
			font-size: 1.33em;
		}	

		.single-date {
			color: $red;
			font-weight: 700;
			display: inline-block;
			margin-right: 8px;
		}
		.single-cat {
			margin-left: 8px;
			display: inline-block;
		}
	}

	.single-content {
		margin: 20px 0;

		@media #{md('medium')} {
			margin: 40px 0;
		}
	}

	.single-tags {
		font-size: 1.1em;
		font-weight: 700;
		color: $red;
		line-height: 30px;
		text-transform: uppercase;
		margin-bottom: 30px;

		@media #{md('medium')} {
			font-size: 1.33em;
			margin-bottom: 60px;
		}

		label {
			display: inline-block;
		}

		ul {
			display: inline;
			margin: 0;

			li {
				display: inline-block;

				a {
					font-size: 0.85em;
					background: $grey-light;
					display: inline-block;
					top: -1px;
					color: $grey-dark;
					padding: 0 10px;
					vertical-align: middle;
					margin: 4px 2px;
					overflow: hidden;
					transform: translateZ(0);
					will-change: color;
					transition: all 0.8s $out-quint;

					@media #{md('medium')} {
						margin: 0 8px;
						font-size: 0.65em;
					}

					&:before {
						content:'';
						transform: skewY(-15deg) translateY(150%) scaleX(0.6) translateZ(0);
						width: 100%;
						height: 200%;
						background: $red;
						position: absolute;
						top: -50%;
						left: 0;
						will-change: transform;
						transition: all 0.9s $out-quint;
					}

					&:hover {
						color: #FFF !important;
						&:before {
							transform: skewY(-10deg) translateY(0%) scaleX(1.25) translateZ(0);
						}
					}
				}
			}
		}
	}
}

.single-crosseling {
	background: $grey-light;
	padding-top: 30px;
	padding-bottom: 40px;

	@media #{md('medium')} {
		padding-top: 60px;
		padding-bottom: 80px;
	}

	.block-title {
		margin-bottom: 32px;
			
		@media #{md('medium')} {
			margin-bottom: 50px;
		}
	}

	.carousel-communications {
		@media #{md('medium')} {
			margin-top: 20px;
			margin-bottom: 20px;
		}

		.flickity-prev-next-button {
			width: 30px;
			height: 30px;
			background: none;
			bottom: 100%;
			transform: none;
			right: 5px;
			left: auto;
			top: auto;
			margin-bottom: 30px;
			opacity: 0.6;
			transition: all 0.4s ease;
			display: none;
			transform-origin: right center;

			&[disabled] {
				display: none;
			}

			@media #{md('small')} {
				display: block;
			}

			svg {
				width: 20px;
				height: 30px;
				left: 50%;
				top: 50%;
				margin-left: -10px;
				margin-top: -15px;
			}

			&.previous {
				right: 35px;
				transform-origin: left center;
			}

			&:hover {
				opacity: 1;
			}

			&:active {
				transform: scaleY(0.6);
			}
		}
	}
}