.section-infos {
	padding: 20px 0;
	background: $grey-light;

	@media #{md('medium')} {
		padding: 65px 0;
	}
}

.info-item {
	text-align: center;
	border-bottom: 1px solid rgba($grey-dark, 0.2);
	margin-top: 30px;
	padding-bottom: 30px;

	@media #{md('medium')} {
		margin-top: 50px;
		padding-bottom: 40px;
		border-left: 1px solid rgba($grey-dark, 0.2);
		border-bottom: 0;
	}

	&:first-child {
		border-left: 0;
	}

	&:last-child {
		border-bottom: 0;
	}

	a{
		display: block;

		&:hover{
			.icon {
				* {
					fill: $grey-dark;
				}
			}
			.icon_arrow-right {
				animation: hoverInfo 0.7s forwards ease;

				* {
					fill: $red;
				}
			}

			.info-title {
				color: $grey-dark;
			}
		}
	}

	.top-icon {
		height: 60px;
		width: 100%;
		display: block;
		text-align: center;

		.icon {
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.icon {
		display: block;
		margin: auto;

		* {
			transition: all 0.4s ease;
		}
	}

	.info-title {
		display: block;
		font-size: 1.46em;
		color: $grey;
		margin: 1em 0;
		transition: all 0.4s ease;
	}

	.icon_arrow-right {
		* {
			fill: $grey;
		}
	}

	@keyframes hoverInfo {
		0% {
			transform: translateX(0px);
		}
		50% {
			transform: translateX(10px);
		}
		100% {
			transform: translateX(0px);
		}
	}
}