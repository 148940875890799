.communication-item {

	.section-communications & {
		margin-top: 30px;
		margin-bottom: 30px;
		border-bottom: 1px solid rgba($grey-dark, 0.1);

		@media #{md("medium")} {
			border-bottom: none;
		}

		&:before {
			content:'';
			right: 0;
			height: 100%;
			position: absolute;
			top: 0;
			background: $grey-light;
			transition: all 0.5s ease;
			left: $grid-gutter--smallest / 2;
			display: none;

			// Media queries
			@media #{md("smaller")} {
				left: $grid-gutter--smaller / 2;
			}

			@media #{md("small")} {
				left: $grid-gutter--small / 2;
			}

			@media #{md("medium")} {
				left: $grid-gutter--medium / 2;
				display: block;
			}

			@media #{md("large")} {
				left: $grid-gutter--large / 2;
			}

			@media #{md("larger")} {
				left: $grid-gutter--larger / 2;
			}
		}

		&:hover {
			&:before {
				background: rgba($grey-light, 0.3);
			}
		}
	}

	.widget & {
		border-top: 1px solid rgba($grey, 0.1);
		margin-top: 30px;
		padding-top: 20px;

		&:first-child {
			border-top: 0;
			margin-top: 0;
		}
	}

	.communication-link {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	
	.communication-img {
		display: block;
		width: 100%;
		z-index: z('default', 1);
		
		@media #{md('medium')} {
			margin-bottom: 1em;

			.section-communications & {
				margin-bottom: 0;
			}
		}

		a {
			min-height: 130px;	
			display: block;
			width: 100%;

			@include hover-img();
		}

		img {
			display: block;
			max-width: 100%;

			&.flickity-lazyloaded,
			&.is-loaded {
				width: 100%;
			}
		}
	}

	.communication-info {
		margin: 0.5em 0;
		color: $red;

		.section-communications & {
			margin-top: 1em;
		}
	}

	.communication-title {
		text-transform: none;
		font-size: 1.53em;
		margin: 0;
		margin-bottom: 0.5em;
		line-height: 1.08em;
		height: #{1.08*4}em;

		.section-communications & {
			height: auto;
			margin-top: 0.5em;
			margin-bottom: 0.4em;
		}
		.widget & {
			height: auto;
			margin-top: 0.5em;
			margin-bottom: 1.2em;
		}

		a {
			display: block;

			&:hover {
				color: $grey;
			}
		}
	}

	.communication-cat {
		color: $grey;
		text-transform: uppercase;
		margin-bottom: 0.4em;
		font-size: 0.93em;
		line-height: 1.28em;
		height: #{1.28*2}em;

		.section-communications &,
		.widget & {
			height: auto;
			margin-bottom: 1.4em;
		}

	}

	.communication-desc {
		min-height: 1em;
	}
}

.section-communications {

}