/*---------------------------------------*\
		Styles et réglages globaux
\*---------------------------------------*/

// Couleurs
$grey		: #8e8e8e;
$grey-dark	: #262626;
$grey-light	: #f3f3f2;
$black		: #151515;
$red		: #e22b28;

// Typographie
$font-family-sans: 'Source Sans Pro', sans-serif;
$font-family-serif: 'Merriweather', serif;
$font-size: 15px;
$line-height: 1.4;
$font-color: $grey-dark;
$selection-bg: #00f;
$selection-color: #fff;

// Fontfaces
$font-dir: "/assets/fonts/";
$fontfaces: (
	// "Webfont Sans" "nom-du-fichier-sans-regular" 400 normal,
	// "Webfont Sans" "nom-du-fichier-sans-bold" 700 normal,
	// "Webfont Serif" "nom-du-fichier-serif-regular" 400 normal,
	// "Webfont Serif" "nom-du-fichier-serif-regular-italic" 400 italic
);



/*------------------------------------*\
		Layout
\*------------------------------------*/

// Body
$layout-body-padding--smallest: 1em;
$layout-body-padding--small: 2em;

// Layout
$layout-main--width: 100%;
$layout-main--max-width: 1280px;

// Grille
$grid--columns: 12;
$grid-gutter--smallest: 15px;
$grid-gutter--smaller:  15px;
$grid-gutter--small:  20px;
$grid-gutter--medium: 20px;
$grid-gutter--large:  30px;
$grid-gutter--larger: 30px;



/*------------------------------------*\
		Images and SVG dirs
\*------------------------------------*/

$img-dir: "/assets/img/layout/";
$svg-dir: "/assets/svg/layout/";



/*------------------------------------*\
		Z-indexes
\*------------------------------------*/

$z-layers: (
	"goku"     : 9000,
	"modal"    : 200,
	"dropdown" : 100,
	"default"  : 1,
	"limbo"    : -999
);



/*------------------------------------*\
		Media queries breakpoints
\*------------------------------------*/

$md-breakpoints: (
	"largest" : 1920,
	"larger"  : 1440,
	"large"   : 1280,
	"normal"  : 1024,
	"medium"  : 992,
	"small"   : 768,
	"smaller" : 480,
	"retina"  : "only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-resolution: 240dppx)"
);



/*------------------------------------*\
		Easing
\*------------------------------------*/

$in-quad: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$out-quad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$in-out-quad: cubic-bezier(0.455, 0.030, 0.515, 0.955);

$in-cubic: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$out-cubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);

$in-quart: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$out-quart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$in-out-quart: cubic-bezier(0.770, 0.000, 0.175, 1.000);

$in-quint: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$out-quint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$in-out-quint: cubic-bezier(0.860, 0.000, 0.070, 1.000);

$in-sine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$out-sine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$in-out-sine: cubic-bezier(0.445, 0.050, 0.550, 0.950);

$in-expo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$out-expo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$in-out-expo: cubic-bezier(1.000, 0.000, 0.000, 1.000);

$in-circ: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$out-circ: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$in-out-circ: cubic-bezier(0.785, 0.135, 0.150, 0.860);

$in-back: cubic-bezier(0.600, -0.280, 0.735, 0.045);
$out-back: cubic-bezier(0.175, 00.885, 0.320, 1.275);
$in-out-back: cubic-bezier(0.680, -0.550, 0.265, 1.550);

$out-back-t: cubic-bezier(0.535, 1.650, 0.635, 1.005);