.tab {
	padding: 20px 0;

	@media #{md('medium')} {
		padding: 60px 0;
	}

	.tabs{
		margin: 0;
		list-style: none;
		padding: 5px 0;

		@media #{md('medium')} {
			padding: 35px 0;
		}

		&:after {
			content:'';
			clear: both; 
			display: table;
		}

		li {
			display: block;
			margin-bottom: 15px;

			@media #{md('medium')} {
				//float: left;
				display: table-cell;
				white-space: nowrap;
			}

			a {
				display: block;
				line-height: 1.1em;
				padding: 1em;
				color: $grey;
				font-weight: 700;
				margin-left: -1px;
				border: 1px solid $grey-light;

				@media #{md('medium')} {
					font-size: 1.46em;
				}

				&:after {
					content:'';
					position: absolute;
					top: 100%;
					left: 50%;
					transition: all 0.3s ease 0s;
					transform: translateY(-100%);
					opacity: 0;

					@media #{md('small')} {
						margin-left: -16px;
						@include triangle-down(32px, 16px, $grey-light);
					}
				}

				.icon {
					margin-right: 10px;

					* {
						transition: all 0.4s ease;
						fill: $grey;
					}
				}
			}

			&.current {
				a {
					background: $grey-light;
					color: $grey-dark;

					&:after {
						opacity: 1;
						transform: translateY(0);
						transition-delay: 0.2s;
					}

					.icon {
						* {
							fill: $red;
						}
					}
				}
			}
		}
	}

	.tab-item {
		overflow: hidden;
		height: 0;
		//display: none;

		&:first-child {
			height: auto;
		}
	}
}