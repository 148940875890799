.services-header{
	background: $grey-light;
	margin-bottom: 20px;

	@media #{md('small')} {
		margin-bottom: 40px;
	}

	img {
		display: block;
		width: 100%;
	}

	h3 {
		font-size: 1.1em;
		color: $red;
		text-transform: uppercase;
		letter-spacing: 0.01em;
		margin-bottom: 0.6em;
	}

	hr {
		border: 0;
		width: 100%;
		height: 1px;
		background: rgba($grey-dark, 0.1);
		margin: 1.4em 0;
	}

	.icon {
		margin-right: 5px;
		transition: all 0.4s ease;
	}

	a {
		&:hover {
			.icon {
				margin-right: 8px;
			}
		}
	}

	.services-contacts {
		margin-top: 25px;

		padding-left: $grid-gutter--smallest; 

		@media #{md("smaller")} {
			padding-left: $grid-gutter--smaller;
		}

		@media #{md("small")} {
			padding-left: $grid-gutter--small;
		}

		@media #{md("medium")} {
			padding-left: $grid-gutter--medium;
		}

		@media #{md("large")} {
			padding-left: $grid-gutter--large;
		}

		@media #{md("larger")} {
			padding-left: $grid-gutter--larger;
		}
	}
	.services-schedules {
		margin-top: 25px;
		color: $grey;
	}
}

.services-footer {
	margin-bottom: 20px;

	@media #{md('medium')} {
		margin-bottom: 60px;
	}
}

.services-links {
	color: $grey;

	h3 {
		font-size: 1.1em;
		color: $red;
		text-transform: uppercase;
		letter-spacing: 0.01em;
		margin: 0;
		margin-bottom: 0.6em;
	}

	ul {
		margin: 0;
		list-style:none;
		margin-bottom: 2em;
		padding: 0;

		li {
			padding-left: 10px;

			&:before {
				content:'•';
				color: $grey;
				top: $line-height / 2;
				left: 0;
				position: absolute;

			}
		}

		a {
			display: inline-block;

			&:hover {
				transform: translateX(5px);
				color: $grey-dark;
			}
		}
	}
}

.services-list-infos {
	margin-bottom: 60px;
}