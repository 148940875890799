.section-gouvernement-team {
	padding-top: 80px;
	padding-bottom: 40px;
	background: $grey-light;
	margin-top: -60px;

	@media #{md('small')} {
		margin-top: -87px;
		padding-top: 160px;
		padding-bottom: 100px;
	}

	img{
		width: 100%;
		display: block;
		margin-bottom: $grid-gutter--smallest;

		// Media queries
		@media #{md("smaller")} {
			margin-bottom: $grid-gutter--smaller;
		}

		@media #{md("small")} {
			margin-bottom: $grid-gutter--small;
		}

		@media #{md("medium")} {
			margin-bottom: $grid-gutter--medium;
		}

		@media #{md("large")} {
			margin-bottom: $grid-gutter--large;
		}

		@media #{md("larger")} {
			margin-bottom: $grid-gutter--larger;
		}
	}

	.grid__row{
		@media #{md("medium")} {
			display: flex;
			align-items: stretch;
		}

		.grid__row {
			height: 100%;
			display: block;
		}
	}
}

.team-item {
	text-align: center;
	margin-bottom: $grid-gutter--smallest;
	height: auto;

	// Media queries
	@media #{md("smaller")} {
		margin-bottom: $grid-gutter--smaller;
	}

	@media #{md("small")} {
		margin-bottom: $grid-gutter--small;
	}

	@media #{md("medium")} {
		margin-bottom: $grid-gutter--medium;
		height: calc(50% - #{$grid-gutter--medium});
	}

	@media #{md("large")} {
		margin-bottom: $grid-gutter--large;
		height: calc(50% - #{$grid-gutter--large});
	}

	@media #{md("larger")} {
		margin-bottom: $grid-gutter--larger;
		height: calc(50% - #{$grid-gutter--larger});
	}

	.team-link {
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	&:hover {
		.team-photo {
			&:after {
				opacity: 0.7;
			}

			figure {
				transform: scale(1.1) translateZ(0);
			}
		}
	}

	.team-photo {
		width: 100%;
		height: 140px;
		margin: 0;
		display: block;
		overflow: hidden;

		@media #{md('medium')} {
			height: 50%;
		}

		figure {
			display: block;
			height: 100%;
			width: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			transition: all 0.4s ease-out;
		}

		&:after {
			content:'';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: $grey-dark;
			opacity: 0;
			transition: all 0.3s ease-out;
		}
	}

	.team-info {
		background: #FFF;
		display: block;
		height: 100px;

		@media #{md('medium')} {
			height: 50%;
		}
	}

	.u-table {
		width: 100%;
		height: 100%;
		padding: 0 10px;

		@media #{md('medium')} {
			padding: 0 15px;
		}

		.u-cell {
			width: 100%;
			vertical-align: middle;
		}
	}

	.team-function {
		display: block;
		text-transform: uppercase;
		color: $red;
		font-size: 0.9em;
		margin-bottom: 5px;

		@media #{md('medium')} {
			font-size: 1em;
		}
	}

	.team-name {
		font-size: 1.35em;
		font-weight: 400;
		line-height: 1em;
		margin: 0;

		@media #{md('large')} {
			font-size: 1.53em;
		}
	}
}

.table-team {
	text-align: left;
	width: 100%;
	margin-top: 10px;

	@media #{md('medium')} {
		margin: 20px 0;
	}

	th {
		padding: 25px 12px;
		font-size: 1.33em;
		font-weight: 700;
		border-bottom: 2px solid $grey-light;
		display: none;

		@media #{md('small')} {
			display: table-cell;
		}
	}
	tr {
		border-bottom: 2px solid $grey-light;
		display: block;
		
		@media #{md('small')} {
			display: table-row;
			padding: 0;
			border-bottom: 0;
		}
	}
	td {
		padding: 0 12px;
		width: 25%;
		display: block;
		width: 100%;

		&:first-child {
			padding-top: 15px;

			@media #{md('small')} {
				padding-top: 0;
			}
		}
		&:last-child {
			padding-bottom: 15px;

			@media #{md('small')} {
				padding-bottom: 0;
			}
		}

		@media #{md('small')} {
			display: table-cell;
			line-height: 60px;
			height: 60px;
			width: auto;
			border-bottom: 2px solid $grey-light;
		}

		&:first-child {
			@media #{md('small')} {
				width: 50%;
			}
		}

		label, a {
			@media #{md('small')} {
				display: table-cell;
				vertical-align: middle;
				line-height: 1.2em;
			}
		}

		a {
			color: $red;

			span,
			strong {
				&:after {
					content:'';
					position: absolute;
					bottom: -2px;
					left: 0;
					width: 100%;
					height: 1px;
					background: $red;
					transform: scaleX(0);
					transition: transform 0.5s $in-out-quart;
					transform-origin: right center;
				}
			}

			&:hover {
				span,
				strong {
					&:after {
						transform-origin: left center;
						transform: scaleX(1);
					}
				}
			}
		}

		label {
			font-weight: 700;
		}

		.show-xs {
			display: initial;
			margin-right: 5px;

			@media #{md('small')} {
				display: none; 
			}
		}
	}
} 

.section-gouvernement-infos {

	.sidebar {
		margin-top: 0;

		&:before {
			margin-top: 0;
		}
	}
}