/*------------------------------------*\
		Form
\*------------------------------------*/

input {
	appearance: none;

	&::-ms-clear {
	    display: none;
	}
}

.form {
	padding-top: 0;
	padding-bottom: 2em;

	@media #{md('small')} {
		padding-top: 1.2em;
		padding-bottom: 2em;
	}
}

.form-item {
	margin-bottom: 10px;
	
	@media #{md('small')} {
		margin-top: 30px;
		margin-bottom: 30px;
	}

	.sep {
		width: 6px;
		top: -3px;
		display: inline-block;
		height: 2px;
		background: $grey-dark;
		vertical-align: middle;
		margin-left: 5px;
		margin-right: 3px;

		@media #{md('small')} {
			width: 13px;
			margin-left: 10px;
			top: auto;
			margin-right: 8px;
		}
	}
}


/*------------------------------------*\
		Labels
\*------------------------------------*/

.form-label {
	font-size: 1.07em;
	color: $grey-dark;
	font-weight: 700;
	line-height: 40px;

	sup {
		color: $red;
		top: 0;
	}
}



/*------------------------------------*\
		Basic text input
\*------------------------------------*/

.form-text {
	appearance: none;
	border-radius: 0;
	height: 40px;
	line-height: 40px;
	height: 40px;
	display: inline-block;
	border: none;
	font-size: 1.07em;
	padding: 0 15px;
	background: $grey-light;
	color: $grey-dark;
	margin-bottom: 10px;

	@media #{md('small')} {
		margin-bottom: 0;
	}

	&:not([size]) {
		width: 100%;
	}
}


.form-dab {
	.form-text {
		width: 75px;
		display: inline-block;
		vertical-align: middle;
	}

	.trigger-datepopup {
		margin-left: 10px;
		display: inline-block;
		vertical-align: middle;
		transition: all 0.4s ease;
		top: -2px;
		
		@media #{md('small')} {
			margin-left: 20px;
			top: auto;
		}

		&:hover {
			opacity: 0.7;
		}
	}
}



/*------------------------------------*\
		Basic textarea
\*------------------------------------*/

.form-textarea {
	appearance: none;
	border-radius: 0;
	line-height: 30px;
	display: inline-block;
	border: none;
	font-size: 1.07em;
	padding: 10px 15px;
	background: $grey-light;
	color: $grey-dark;
	width: 100%;
	resize: none;
}


/*------------------------------------*\
		Select list
\*------------------------------------*/

.form-select {
	display: block;

	&:after {
		content:'';
		width: 14px;
		height: 7px;
		position: absolute;
		background: transparent url(#{$svg-dir}chevron.svg) center center no-repeat;
		background-size: contain;
		top: 50%;
		right: 15px;
		margin-top: -3px;
		pointer-events: none;
		transition: all 0.4s ease;
	}

	select {
		appearance: none;
		border-radius: 0;
		cursor: pointer;
		line-height: 40px;
		height: 40px;
		display: block;
		width: 100%;
		border: none;
		font-size: 1.07em;
		padding: 0 15px;
		padding-right: 30px;
		background: $grey-light;
		color: $grey-dark;
	}

	&:hover {
		&:after {
			transform: rotate(180deg);
		}
	}
}



/*------------------------------------*\
		Radio
\*------------------------------------*/

.form-radio {
	display: none;

	&:checked {

		+ label {

			&:before {
				border-color: $red;
				border-width: 6px;
			}
		}
	}

	+ label {
		line-height: 40px;
		display: block;
		cursor: pointer;

		&:before {
			content:'';
			display: inline-block;
			vertical-align: middle;
			top: -2px;
			margin-right: 10px;
			border-radius: 50%;
			border: 1px solid $grey;
			width: 18px;
			height: 18px;
			transition: all 0.2s ease;
		}
	}
}
