/*------------------------------------*\
		Footer
\*------------------------------------*/

// .footer {}
.main-footer {
	padding-top: 30px;
	padding-bottom: 15px;
	background: $grey-dark;
	text-align: center;

	.main-logo {
		margin: auto;
		width: 189px;
		height: 101px;

		@media #{md('medium')} {
			width: 227px;
			height: 120px;
			margin: 0;
		}
	}

	@media #{md('medium')} {
		padding: 50px 0;
		text-align: left;
	}

	h4 {
		margin-top: 0;
		color: #FFF;
		font-size: 1em;
		display: none;
		margin-bottom: 2em;

		@media #{md('medium')} {
			display: block;
		}
	}
	.links {
		display: none;

		@media #{md('medium')} {
			display: block;
		}
		li {
			padding-bottom: 7px;

			.icon {
				margin-right: 8px;

				* {
					transition: all 0.3s ease;
				}
			}
		}
	}

	a {
		color: $grey;

		&:hover {
			color: #FFF;
			opacity: 0.9;

			.icon {
				* {
					fill: #FFF;
				}
			}
		}
	}

	.socials-network {
		padding-top: 20px;

		@media #{md('medium')} {
			padding-top: 0;
		}

		a {
			margin: 0 10px;

			@media #{md('medium')} {
				margin: 0;
				margin-right: 5px;
			}
		}
	}
}

.closure {
	line-height: 40px;
	background: $black;
	color: $grey;
	font-weight: 600;
	text-align: center;

	ul {
		margin: 0 auto;
		padding: 0;
		list-style: none;

		li {
			display: inline-block;
			padding: 0 5px;

			&:before {
				content:'|';
				left: -6px;
			}

			&:first-child {
				&:before {
					display: none;
				}
			}

			a {
				&:hover {
					color: #FFF;
				}
			}
		}
	}
}

.overlay,
.overlay-off-canvas {
	position: fixed;
	z-index: z('goku', -10);
	visibility: hidden;
	opacity: 0;
	background: rgba($grey-dark, 0.8);
	width: 100%;
	left: 0;
	top: 0;
	height: 100%;
	transition: all 0.5s ease;

	&.overlay-off-canvas {
		z-index: z('goku', 1);
		
		.open-off-canvas & {
			visibility: visible;
			opacity: 1;
		}
	}

	@media #{md('medium')} { 
		display: none;
	}

	&.overlay {
		.open-menu & {
			visibility: visible;
			opacity: 1;
		}
	}
}