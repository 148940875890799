/*------------------------------------*\
		Grid Advanced
\*------------------------------------*/


/*------------------------------------*\
		Smallest view
\*------------------------------------*/

.col-xs-no-gutter {
	padding-right: 0;
	padding-left: 0;
}

.col-xs-gutter {
	padding-right: $grid-gutter--smallest / 2;
	padding-left: $grid-gutter--smallest / 2;
}



/*------------------------------------*\
		Smaller view
\*------------------------------------*/

@media #{md("smaller")} {

	.col-s-no-gutter {
		padding-right: 0;
		padding-left: 0;
	}

	.col-s-gutter {
		padding-right: $grid-gutter--smaller / 2;
		padding-left: $grid-gutter--smaller / 2;
	}
}



/*------------------------------------*\
		Small view
\*------------------------------------*/

@media #{md("small")} {

	.col-m-no-gutter {
		padding-right: 0;
		padding-left: 0;
	}

	.col-m-gutter {
		padding-right: $grid-gutter--small / 2;
		padding-left: $grid-gutter--small / 2;
	}
}



/*------------------------------------*\
		Medium view
\*------------------------------------*/

@media #{md("medium")} {

	.col-n-no-gutter {
		padding-right: 0;
		padding-left: 0;
	}

	.col-n-gutter {
		padding-right: $grid-gutter--medium / 2;
		padding-left: $grid-gutter--medium / 2;
	}
}



/*------------------------------------*\
		Large view
\*------------------------------------*/

@media #{md("large")} {

	.col-l-no-gutter {
		padding-right: 0;
		padding-left: 0;
	}

	.col-l-gutter {
		padding-right: $grid-gutter--large / 2;
		padding-left: $grid-gutter--large / 2;
	}
}



/*------------------------------------*\
		Larger view
\*------------------------------------*/

@media #{md("larger")} {

	.col-xl-no-gutter {
		padding-right: 0;
		padding-left: 0;
	}

	.col-xl-gutter {
		padding-right: $grid-gutter--larger / 2;
		padding-left: $grid-gutter--larger / 2;
	}
}
