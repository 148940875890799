.section-organisations {
	background: $grey-light;
	margin-top: -60px;
	padding-top: 80px;
	padding-bottom: 30px;

	@media #{md('small')} {
		margin-top: -87px;
		padding-top: 125px;
		padding-bottom: 70px;
	}

	.organisation-item {
		text-align: center;
		padding-top: 20px;
		border-left: 1px solid rgba($grey-dark, 0.2);
		border-bottom: 1px solid rgba($grey-dark, 0.2);

		@media #{md("medium")} {
			border-bottom: 0;
			padding-top: 40px;
			padding-bottom: 20px;
		}

		&:hover {
			&:before {
				opacity: 1;
				transform: translateY(0);
			}

			.icon {
				* {
					fill: $red;
				}
			}

			.organisation-title {
				color: $red;
			}
		}

		&:first-child {
			border-left: 0;
		}
		&:nth-child(3) {
			border-bottom: none;
			border-left-width: 0;

			@media #{md('medium')} {
				border-left-width: 1px;
			}
		}
		&:nth-child(4) {
			border-bottom: none;
		}

		&:before {
			background: rgba(#FFF, 0.5);
			content:'';
			position: absolute;
			top: 0;
			height: 100%;
			opacity: 0;
			transform: translateY(20px);
			transition: all 0.5s ease;
			right: $grid-gutter--smallest / 2;
			left: $grid-gutter--smallest / 2;

			// Media queries
			@media #{md("smaller")} {
				right: $grid-gutter--smaller / 2;
				left: $grid-gutter--smaller / 2;
			}

			@media #{md("small")} {
				right: $grid-gutter--small / 2;
				left: $grid-gutter--small / 2;
			}

			@media #{md("medium")} {
				right: $grid-gutter--medium / 2;
				left: $grid-gutter--medium / 2;
			}

			@media #{md("large")} {
				right: $grid-gutter--large / 2;
				left: $grid-gutter--large / 2;
			}

			@media #{md("larger")} {
				right: $grid-gutter--larger / 2;
				left: $grid-gutter--larger / 2;
			}
		}

		a {
			display: block;
		}

		.icon {
			display: block;
			margin: auto;

			* {
				transition: all 0.3s ease;
				color: $grey;

				@media #{md('medium')} {
					fill: $grey-dark;
				}
			}
		}

		.organisation-title {
			display: block;
			font-size: 1.2em;
			color: $grey;
			line-height: 1.2em;
			font-weight: 400;
			margin-top: 1.35em;
			margin-bottom: 1.35em;
			transition: all 0.3s ease;

			@media #{md('medium')} {
				font-size: 1.6em;
				color: $grey-dark;
			}
		}

		.organisation-desc {
			min-height: 1.125 * 4em;
			font-style: italic;
			color: $grey;
			font-size: 1.07em;
			line-height: 1.125em;
			display: none;

			@media #{md('small')} {
				display: block;
			}

			p {
				margin: 0;
			}
		}
	}
}

.section-thematiques {
	padding-bottom: 10px;
	padding-bottom: 30px;

	@media #{md("medium")} {
		padding-bottom: 20px;
		padding-bottom: 50px;
	}

	.list-themes {
		padding-top: 15px;
		padding-bottom: 0;

		@media #{md("medium")} {
			padding-top: 30px;
			padding-bottom: 40px;
		}
	}
}

.theme-item {
	height: 135px;
	margin-top: $grid-gutter--smallest / 2;
	margin-bottom: $grid-gutter--smallest / 2;

	// Media queries
	@media #{md("smaller")} {
		margin-top: $grid-gutter--smaller / 2;
		margin-bottom: $grid-gutter--smaller / 2;
	}

	@media #{md("small")} {
		margin-top: $grid-gutter--small / 2;
		margin-bottom: $grid-gutter--small / 2;
	}

	@media #{md("medium")} {
		height: 172px;
		margin-top: $grid-gutter--medium / 2;
		margin-bottom: $grid-gutter--medium / 2;
	}

	@media #{md("large")} {
		margin-top: $grid-gutter--large / 2;
		margin-bottom: $grid-gutter--large / 2;
	}

	@media #{md("larger")} {
		margin-top: $grid-gutter--larger / 2;
		margin-bottom: $grid-gutter--larger / 2;
	}

	.u-table {
		height: 100%;
		width: 100%;

		.u-cell {
			vertical-align: middle;
		}
	}

	.theme-img {
		width: 45%;
		float: left;
		height: 100%;
		overflow: hidden;
		background-color: $grey-light;

		figure {
			display: block;
			margin: 0;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			transform: scale(1) translateZ(0);
			transition: all 0.4s ease-out;
		}

		&:after {
			content:'';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: $grey-dark;
			opacity: 0;
			transition: all 0.3s ease-out;
		}
	}

	.theme-info {
		background: $grey-light;
		width: 55%;
		float: left;
		height: 100%;
		padding: 0 15px;

		@media #{md("medium")} {
			padding: 0 20px;
		}	
	}

	.theme-cat {
		font-size: 1em;
		line-height: 1em;
		margin: 0;
		text-transform: uppercase;
		font-weight: 400;
		color: $red;
	}

	.theme-title {
		font-size: 1.3em;
		line-height: 1.15em;
		display: inline-block;
		font-weight: 400;
		margin: 0.5em 0;

		@media #{md("medium")} {
			font-size: 1.5em;
		}

		span {
			border-bottom: 1px solid transparent;
			transition: all 0.2s ease;
			line-height: 1em;
		}
	}

	.theme-link {
		position: absolute;
		top: 0;
		z-index: z('default', 1);
		left: 0;
		width: 100%;
		height: 100%;
	}

	&:hover{
		.theme-title {
			span {
				border-bottom-color: $grey-dark;
			}
		}
		.theme-img{
			&:after {
				opacity: 0.7;
			}

			figure{
				transform: scale(1.1) translateZ(0);
			}
		}
	}
}

.seciton-events {
	.block-communications {
		padding-top: 10px;
		padding-bottom: 20px;

		@media #{md('medium')} {
			padding-top: 60px;
			padding-bottom: 60px;
		}

		.carousel-communications {
			margin-bottom: 20px;

			@media #{md('medium')} {
				margin-bottom: 50px;
			}
		}
	}

	.block-title {
		color: $grey-dark;
	}
}