.entry-body {
	line-height: 1.5em;

	big {
		font-size: 125%;
		line-height: 1.45em;
	}

	video {
		display: block;
		margin: 1.5em 0;
	}

	img {
		max-width: 100%;
	}

	p {
		margin-bottom: 1.2em;
	}

	a {
		color: $red;

		&:hover {
			opacity: 0.7;
		}
	}

	h2 {
		font-size: 1.2em;
		line-height: 1.44em;
		color: $red;
		font-weight: 700;
		text-transform: uppercase;
		margin-top: 0;
		margin-bottom: 1em;
	}

	h3 {
		font-size: 1.1em;
		line-height: 1.5em;
		margin-bottom: 0.6em;
	}

	ul {
		margin-bottom: 1em;
		padding: 0;
		list-style: none;

		li {
			padding: 2px 0;
			padding-left: 15px;

			&:before {
				content:'•';
				color: $red;
				top: $line-height / 2;
				left: 0;
				position: absolute;

			}
		}
	}

	ol {
		margin-bottom: 1em;
		padding: 0;
		list-style: none;
		counter-reset: num;

		li {
			padding: 2px 0;
			padding-left: 20px;

			&:before {
				content:counter(num)'.';
				counter-increment: num;
				color: $red;
				top: $line-height / 2;
				left: 0;
				position: absolute;

			}
		}
	}

	blockquote {
		font-size: 1.87em;
		font-weight: 300;
		color: $grey;
		line-height: 1.35em;
		margin: 1em 0;
		
		@media #{md('medium')} {
			margin-left: -8%;
		}

		p {
			margin: 0;

			&:before {
				content:'“ ';
			}
			&:after {
				content:' ”';
			}
		}
	}

	img{

		&.alignleft {
			margin-right: 15px;
			float: left;
			margin-bottom: 1em;

			@media #{md('medium')} {
				margin-left: -8%;
				margin-right: 30px;
			}
		}

		&.alignright {
			margin-left: 30px;
			float: right;
			margin-bottom: 1em;
		}

		&.aligncenter {
			margin: auto;
			display: block;
		}
	}

	table {
		margin-bottom: 1em;

		th {
			line-height: 1.2em;
			padding: 10px 30px;
			background: rgba($grey-dark, 0.1);
			font-size: 1.1em;
			font-weight: 700;
			text-align: left;
		}

		td {
			padding: 5px 30px;
		}

		tr {
			&:nth-child(even) {
				td {
					background: rgba($grey-dark, 0.05);
				}
			}
		}
	}
}