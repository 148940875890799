.section-depts-services {
	padding-bottom: 20px;

	@media #{md('medium')} {
		padding-bottom: 40px;
	}

	.list-depts {
		padding-top: 20px;
		padding-bottom: 10px;

		@media #{md('medium')} {
			padding-top: 20px;
			padding-bottom: 40px;
		}
	}
}
.dept-item {
	position: relative;
	margin-top: $grid-gutter--smallest / 2;
	margin-bottom: $grid-gutter--smallest / 2;

	// Media queries
	@media #{md("smaller")} {
		margin-top: $grid-gutter--smaller / 2;
		margin-bottom: $grid-gutter--smaller / 2;
	}

	@media #{md("small")} {
		margin-top: $grid-gutter--small / 2;
		margin-bottom: $grid-gutter--small / 2;
	}

	@media #{md("medium")} {
		position: static;
		margin-top: $grid-gutter--medium / 2;
		margin-bottom: $grid-gutter--medium / 2;
	}

	@media #{md("large")} {
		margin-top: $grid-gutter--large / 2;
		margin-bottom: $grid-gutter--large / 2;
	}

	@media #{md("larger")} {
		margin-top: $grid-gutter--larger / 2;
		margin-bottom: $grid-gutter--larger / 2;
	}

	&.active {
		.dept-link {
			&:before {
				opacity: 1;
				transform: translateY(0);
				transition-delay: 0.4s;
			}
		}
	}

	.dept-link {
		display: block;

		&:before {
			content:'';
			position: absolute;
			top: 100%;
			left: 50%;
			margin-left: -15px;
			@include triangle-up(30px, 15px, $grey-light);
			margin-top: $grid-gutter--smallest / 2;
			transition: all 0.2s ease 0s;
			opacity: 0;
			transform: translateY(100%);

			// Media queries
			@media #{md("smaller")} {
				margin-top: $grid-gutter--smaller / 2;
			}

			@media #{md("small")} {
				margin-top: $grid-gutter--small / 2;
			}

			@media #{md("medium")} {
				margin-top: $grid-gutter--medium / 2;
			}

			@media #{md("large")} {
				margin-top: $grid-gutter--large / 2;
			}

			@media #{md("larger")} {
				margin-top: $grid-gutter--larger / 2;
			}
		}
		
		@include hover-img();

		.icon {
			margin-left: -6px;
			transform: translateY(-10px);
				
		}

		&:after {
			background: rgba($red, 0.9);
		}

		&:hover {
			.icon {
				transform: translateY(-10px); 

				@media #{md("medium")} {
					transform: translateY(10px); 
				}
			}

			.dept-title {
				margin-top: -10px;
				opacity: 0;
				transition-delay: 0s;
			}
		}
	}

	.dept-img {
		display: block;
		width: 100%;
		min-height: 100px;

		&:after {
			content:'';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: $grey-dark;
			opacity: 0.5;
		}

		img.is-loaded {
			width: 100%;
			display: block;
		}
	}

	.dept-title {
		font-size: 1.7em;
		margin: 0;
		color: #FFF;
		display: block;
		font-weight: 700;
		text-align: center;
		width: 100%;
		padding: 0 25px;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		transition: all 0.3s ease 0.3s;
	}

	.dept-list {
		position: absolute;
		top: 0;
		opacity: 0;
		visibility: hidden;
		background: $grey-light;
		@include clearfix();
		left: $grid-gutter--smallest / 2;
		right: $grid-gutter--smallest / 2;
		margin-top: $grid-gutter--smallest / 2;
		margin-bottom: $grid-gutter--smallest / 2;

		// Media queries
		@media #{md("smaller")} {
			left: $grid-gutter--smaller / 2;
			right: $grid-gutter--smaller / 2;
			margin-top: $grid-gutter--smaller / 2;
			margin-bottom: $grid-gutter--smaller / 2;
		}

		@media #{md("small")} {
			left: $grid-gutter--small / 2;
			right: $grid-gutter--small / 2;
			margin-top: $grid-gutter--small / 2;
			margin-bottom: $grid-gutter--small / 2;
		}

		@media #{md("medium")} {
			left: $grid-gutter--medium / 2;
			right: $grid-gutter--medium / 2;
			margin-top: $grid-gutter--medium / 2;
			margin-bottom: $grid-gutter--medium / 2;
		}

		@media #{md("large")} {
			left: $grid-gutter--large / 2;
			right: $grid-gutter--large / 2;
			margin-top: $grid-gutter--large / 2;
			margin-bottom: $grid-gutter--large / 2;
		}

		@media #{md("larger")} {
			left: $grid-gutter--larger / 2;
			right: $grid-gutter--larger / 2;
			margin-top: $grid-gutter--larger / 2;
			margin-bottom: $grid-gutter--larger / 2;
		}

		.trigger-close {
			position: absolute;
			z-index: z('default');
			right: 30px;
			top: 30px;
			
			.icon_cross {
				width: 15px;
				height: 15px;

				i {
					&:after {
						background: $grey-dark;
					}
				}
			}
		}

		h4 {
			margin: 1.5em 0;
		}

		ul {
			list-style: none;
			padding: 0;
			margin-top: 0;
			margin-bottom: 0.5em;
			padding-bottom: 1em;
			border-bottom: 2px solid $grey-light;

			@media #{md('medium')} {
				border-bottom: none;
				padding: 0;
				margin-top: 5px;
				margin-bottom: 40px;
			}

			li {
				display: block;
			
				@media #{md('medium')} {
					margin-bottom: -1px;
				}

				a {
					display: block;
					padding: 0 16px;
					padding-right: 60px;
					transition: all 0.4s ease;
					line-height: 50px;

					@media #{md('medium')} {
						line-height: 70px;
						border-top: 1px solid #dededd;
						border-bottom: 1px solid #dededd;
					}

					&:before {
						content:'';
						width: 100%;
						position: absolute;
						left: 0;
						top: 0;
						height: 100%;
						background: #dededd;
						transform: scaleX(0);
						transform-origin: left center;
						transition: all 0.4s $in-out-expo;
					}

					span {
						display: inline-block;
						vertical-align: middle;
						line-height: 1.2em;
					}

					.icon {
						position: absolute;
						right: 16px;
						top: 50%;
						margin-top: -6px;
						opacity: 0;
						transform: translateX(-20px);
						transition: all 0.4s $in-out-expo;

						* {
							fill: $grey-dark;
						}
					}

					&:hover {
						//font-weight: 700;

						&:before {
							transform: scaleX(1);
						}

						.icon {
							opacity: 1;
							transform: translateX(0);
							transition-delay: 0.15s;
						}
					}
				}
			}
		}
	}

}

.section-jobs {
	background: $grey-light;
	padding-bottom: 25px;

	@media #{md('medium')} {
		padding-bottom: 50px;
	}

	.list-jobs {
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;
		padding-top: 10px;
		padding-bottom: 15px;

		@media #{md('medium')} {
			padding-bottom: 30px;
		}
	}
}

.job-item {
	margin-top: $grid-gutter--smallest / 2;
	margin-bottom: $grid-gutter--smallest / 2;
	float: none;
	display: flex;

	// Media queries
	@media #{md("smaller")} {
		margin-top: $grid-gutter--smaller / 2;
		margin-bottom: $grid-gutter--smaller / 2;
	}

	@media #{md("small")} {
		margin-top: $grid-gutter--small / 2;
		margin-bottom: $grid-gutter--small / 2;
	}

	@media #{md("medium")} {
		margin-top: $grid-gutter--medium / 2;
		margin-bottom: $grid-gutter--medium / 2;
	}

	@media #{md("large")} {
		margin-top: $grid-gutter--large / 2;
		margin-bottom: $grid-gutter--large / 2;
	}

	@media #{md("larger")} {
		margin-top: $grid-gutter--larger / 2;
		margin-bottom: $grid-gutter--larger / 2;
	}

	.job-content {
		background: rgba(#FFF, 1);
		padding: 15px 20px;
		transition: all 0.4s ease;
	}

	.job-title {
		font-size: 1.33em;
		margin-top: 5px;
		margin-bottom: 10px;
	}

	.job-info {
		color: $red;
	}

	.job-link {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
	}

	.icon {
		position: absolute;
		right: 20px;
		bottom: 20px;
		transform: translateX(-20px);
		opacity: 0;
		pointer-events: none;
		transition: all 0.5s $in-out-expo;

		* {
			fill: $red;
		}
	}

	&:hover {
		.job-content {
			background: rgba(#FFF, 0.5);
		}
		.icon {
			transform: translateX(0);
			//opacity: 1;
		}
	}
}

.section-guichet {
	z-index: z('default');

	.block-news,
	.bg-news {
		@media #{md('medium')} {
			transform: translateY(-100px);
			margin-bottom: -100px;
		}
	}

	.list-themes {
		margin-bottom: 20px;

		@media #{md('medium')} {
			margin-bottom: 40px;
		}
	}
}