/*------------------------------------*\
		Positionnement & Display
\*------------------------------------*/

// Display
.u-none   { display: none; }
.u-table  { display: table; }
.u-cell   { display: table-cell; }
.u-inlbl  { display: inline-block; }

// Clearfix
.cf { @include clearfix; }



/*------------------------------------*\
		Typographie
\*------------------------------------*/

// Alignement de texte
.u-tac { text-align: center; }
.u-tal { text-align: left; }
.u-tar { text-align: right; }




/*------------------------------------*\
		Accessibilité
\*------------------------------------*/

/**
 * A class to hide labels and stuff
 * for accessibility
 * @author Facebook Accessibility Team
 * @source https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */
.u-accessible-item {
	position: absolute;
	width: 1px;
	height: 1px;
	white-space: nowrap;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
}
