.section-sidebar {
	&.border-bottom {
		&:after {
			content:'';
			left: 50%;
			bottom: 0;
			position: absolute;
			width: $layout-main--width;
			max-width: $layout-main--max-width;
			transform: translateX(-50%);
			background: rgba($grey-dark, 0.2);
			height: 1px;
		}
	}

	.toggle-off-canvas {
		display: block;
		font-size: 1.73em;
		text-transform: uppercase;
		color: $grey-dark;
		font-weight: 700;
		border-bottom: 1px solid #cacac9;
		padding-bottom: 10px;

		i {
			position: absolute;
			right: 8px;
			top: 50%;
			margin-top: -5px;
			width: 24px;
			height: 2px;
			background: $red;

			&:before {
				content:'';
				height: 2px;
				width: 20px;
				background: $red;
				top:-8px;
				position: absolute;
				left: 0;
			}

			&:after {
				content:'';
				height: 2px;
				background: $red;
				bottom: -8px;
				left: 0;
				width: 15px;
				position: absolute;
			}
		}

		@media #{md('small')} {
			margin-top: -10px;
		}
		@media #{md('medium')} {
			display: none;
		}
	}
}

.sidebar {
	//padding-top: 10px;
	padding-bottom: 20px;
	position: static;
	-webkit-overflow-scrolling: touch; 
	margin-top: -10px;

	@media #{md('small')} {
		margin-top: -20px;
	}

	@media #{md('medium')} {
		padding-bottom: 50px;
		//padding-top: 20px;
	}

	&:before {
		content:'';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 75%;
		transform: translateX(-1px);
		display: none;
		width: 1px;
		background: rgba($grey-dark, 0.2);
		margin-left: -$grid-gutter--smallest / 2;
		margin-top: -10px;

		// Media queries
		@media #{md("smaller")} {
			margin-left: -$grid-gutter--smaller / 2;
		}

		@media #{md("small")} {
			margin-top: -20px;
			margin-left: -$grid-gutter--small / 2;
		}

		@media #{md("medium")} {
			display: block;
			margin-left: -$grid-gutter--medium / 2;
		}

		@media #{md("large")} {
			margin-left: -$grid-gutter--large / 2;
		}

		@media #{md("larger")} {
			margin-left: -$grid-gutter--larger / 2;
		}
	}

	&.left{
		&:before {
			right: 75%;
			margin-right: -$grid-gutter--smallest / 2;
			margin-left: 0;
			left: auto;

			// Media queries
			@media #{md("smaller")} {
				margin-right: -$grid-gutter--smaller / 2;
			}

			@media #{md("small")} {
				margin-right: -$grid-gutter--small / 2;
			}

			@media #{md("medium")} {
				margin-right: -$grid-gutter--medium / 2;
			}

			@media #{md("large")} {
				margin-right: -$grid-gutter--large / 2;
			}

			@media #{md("larger")} {
				margin-right: -$grid-gutter--larger / 2;
			}
		}
	}

	&.off-canvas {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		overflow-y: scroll;
		z-index: z('goku');
		background: #FFF;
		padding-top: 20px;
		padding-bottom: 20px;
		max-width: 270px;
		transform: translateX(100%);
		display: none;

		@media #{md("small")} {
			max-width: 360px;
		}
		@media #{md("medium")} {
			display: block;
			position: static;
			overflow-y: visible;
			bottom: auto;
			background-color: transparent;
			padding-top: 0;
			padding-bottom: 50px;
			max-width: 100%;
			transform: none;
		}

		&.duplicate {
			display: block;
			transition: all 0.6s $out-expo;

			.open-off-canvas & {
				transform: translateX(0);
			}

			@media #{md("medium")} {
				display: none;
			}
		}
	}


	.close-off-canvas {
		position: absolute;
		top: 25px;
		right: 10px;

		.icon_cross {
			i {
				background: $red;
			}
		}
		
		@media #{md('medium')} {
			display: none;
		}
	}


	.widget {
		padding-top: 10px;
		padding-bottom: 15px;

		@media #{md('medium')} {
			padding-top: 20px;
			padding-bottom: 40px;
		}
		
		&:first-child {

			&:before {
				display: none;
			}
		}

		&:before {
			content:'';
			top: 0;
			height: 1px;
			background: rgba($grey-dark, 0.2);
			position: absolute;
			left: -$grid-gutter--smallest;
			right: -$grid-gutter--smallest / 2;

			// Media queries
			@media #{md("smaller")} {
				left: -$grid-gutter--smaller;
				right: -$grid-gutter--smaller / 2;
			}

			@media #{md("small")} {
				left: -$grid-gutter--small;
				right: -$grid-gutter--small / 2;
			}

			@media #{md("medium")} {
				left: -$grid-gutter--medium;
				right: -$grid-gutter--medium / 2;
			}

			@media #{md("large")} {
				left: -$grid-gutter--large;
				right: -$grid-gutter--large / 2;
			}

			@media #{md("larger")} {
				left: -$grid-gutter--larger / 2;
				right: -$grid-gutter--larger / 2;
			}
		}
	}
}