/*------------------------------------*\
		Main
\*------------------------------------*/

.wrapper {
	transition: all 0.6s $out-expo;

	.open-off-canvas & {
		transform: translateX(-270px);

		@media #{md("small")} {
			transform: translateX(-360px);
		}
	
		@media #{md('medium')} {
			transform: none;
		}
	}
	
	@media #{md('medium')} {
		transition: none;
	}
}

.main {
	padding-top: 80px;

	@media #{md('medium')} {
		padding-top: 110px;
	}
}

.hero {
	min-height: 80px;
	background: $grey-light;
	z-index: z('default', 2);
	overflow: hidden;

	&:before {
		top: 70px;
	}

	@media #{md('large')} {
		//margin: 0 20px;
	}

	.home & {	
		figure {
			background-color: transparent;
			
			@media #{md('medium')} {
				height: 400px;
			}
		}
	}

	figure {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		width: 100%;
		margin: 0;
		height: 150px; 
		display: block;
		transform: scale(1.2) translateZ(0);
		transform-origin: center top;

		@media #{md('medium')} {
			height: 300px;
		}
		
		&.is-loaded {
			transform: scale(1) translateZ(0);
			transition: opacity 0.3s ease, transform 1s $in-out-quart 0.1s;
		}

		&.big {
			@media #{md('medium')} {
				height: 400px;
			}
		}
	}

	.page-title {
		position: absolute;
		top: 50%;
		left: 0;
		text-align: center;
		width: 100%;
		transform: translateY(-50%);
		margin: 0;
		color: #FFF;
	}
}

.page-title {
	font-size: 2em;
	margin: 0.5em 0;
	color: $grey-dark;
	line-height: 1.08em;

	@media #{md('medium')} {
		font-size: 4.67em;
	}
}

.block-title {
	padding-top: 15px;
	margin-top: 0.6em;
	margin-bottom: 1.1em;
	text-transform: uppercase;
	line-height: 1.1em;
	font-size: 1.73em;

	@media #{md('medium')} {
		margin-top: 1.2em;
	}

	&:before {
		content:'';
		width: 30px;
		position: absolute;
		left: 0;
		top: 0;
		height: 2px;
		background: $red;
	}

	&.u-tac {
		&:before {
			left: 50%;
			margin-left: -15px;
		}
	}
}

.section-docs {
	margin-bottom: 20px;

	@media #{md('medium')} {
		margin-bottom: 60px;
	}
}

.doc-item {
	font-size: 1.2em;
	height: 90px;
	background: $grey-light;
	display: block;
	line-height: 1.1em;
	padding-left: 20px;
	padding-right: 20px;
	transition: all 0.5s ease;
	margin: 8px 0;

	@media #{md("smaller")} {
		margin-top: $grid-gutter--smaller / 2;
		margin-bottom: $grid-gutter--smaller / 2;
	}

	@media #{md("small")} {
		margin-top: $grid-gutter--small / 2;
		margin-bottom: $grid-gutter--small / 2;
	}

	@media #{md("medium")} {
		font-size: 1.53em;
		height: 110px;
		margin-top: $grid-gutter--medium / 2;
		margin-bottom: $grid-gutter--medium / 2;
	}

	@media #{md("large")} {
		margin-top: $grid-gutter--large / 2;
		margin-bottom: $grid-gutter--large / 2;
	}

	@media #{md("larger")} {
		margin-top: $grid-gutter--larger / 2;
		margin-bottom: $grid-gutter--larger / 2;
	}

	&:hover {
		background: rgba($grey-light, 0.5);

		.icon {
			transform: translateX(0);
			opacity: 1;
		}
	}

	.icon {
		position: absolute;
		right: 20px;
		top: 50%;
		margin-top: -6px;
		opacity: 0;
		transform: translateX(-10px);
		transition: all 0.4s $in-out-expo;

		* {
			fill: $grey-dark;
		}
	}

	.u-table {
		height: 100%;
		width: calc(100% - 50px);

		.u-cell {
			vertical-align: middle;
		}
	}

	.subtitle {
		text-transform: uppercase;
		color: $red;
		line-height: 1em;
		padding-bottom: 5px;
		display: block;
		font-size: 0.65em;
	}
}