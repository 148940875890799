.js-lazy {
	opacity: 0;
	transition: opacity 0.4s ease-out;

	&.is-loaded {
		opacity: 1;
	}
}

.loading{

	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 50px;
		height: 50px;
		margin-top: -25px;
		margin-left: -25px;
		content:'';
		background: transparent url(#{$svg-dir}loader.svg) center center no-repeat;
		background-size: contain;
		transition: opacity 0.3s ease-out; 
	}

	&.is-loaded {
		&:before {
			opacity: 0;
		}
	}
}