.treeview {
	h3 {
		text-transform: uppercase;
		font-size: 1.04em;
		font-weight: 700;
		color: $grey;
	}

	.icon-more {
		display: inline-block;
		width: 16px;
		height: 16px;
		border: 1px solid $grey-dark;
		vertical-align: middle;
		top: 7px;
		margin-right: 6px;
		cursor: pointer;
		position: absolute;
		left: 0;
		z-index: 1;

		&:before {
			content:'';
			width: 8px;
			height: 1px;
			background: $grey-dark;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) translateZ(0);
		}

		&:after {
			content:'';
			height: 8px;
			width: 1px;
			background: $grey-dark;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(0deg) translateZ(0);
			transition: all 0.3s ease;
		}

		& + a {
			padding: 6px 0;
			padding-left: 26px;
			color: $grey-dark;

			&:hover {
				color: lighten($grey-dark, 20%);
			}
		}
	}

	ul {
		margin: 0.5em 0;
		padding: 0;
		list-style: none;

		li {

			&.active {
				& > a {
					color: $red;
				}

				& > .icon-more {
					&:after {
						transform: translate(-50%, -50%) rotate(90deg) translateZ(0);
					}
				}
			}
		}

		a {
			color: $grey-dark;
			line-height: 1.2em;
			padding: 6px 0;
			padding-left: 26px;
			display: inline-block;

			&:hover{
				color: lighten($grey-dark, 20%);
			}
		}

		ul {
			margin: 0;
			height: 0;
			padding-left: 30px;
			overflow: hidden;

			li {
				padding: 1px 0;
			}

			a {
				color: $grey;
				line-height: 1em;
				padding: 0;

				&:hover {
					color: rgba($grey-dark, 0.9);
					opacity: 1;
				}
			}
		}
	}
}