.twitter-tweets {
	background: $grey-light;
	padding-top: 10px;
	padding-bottom: 30px;

	@media #{md('medium')} {
		padding-top: 15px;
	}

	h2 {
		text-transform: uppercase;
		color: $grey;
		font-size: 1.73em;

		@media #{md('medium')} {
			text-align: center;
		}

		.icon_tw,
		.svg_tw {
			width: 40px;
			height: 32px;
			margin-right: 2px;
			top: -2px;
			display: none;

			@media #{md('medium')} {
				display: inline-block;
			}
		}
	}

	.link {
		color: $grey;
		position: absolute;
		top: 50%;
		right: 0;
		margin-top: 0.5em;
		margin-right: 15px;
		padding-right: 0;

		@include links($grey-dark);

		@media #{md('medium')} {
			margin-right: 35px;
		}
		@media #{md('large')} {
			right: 50%;
			margin-right: -22%;
		}

		.icon {
			position: relative;
			left: auto;
			margin-top: 0;

			* {
				fill: $grey;
			}
		}
	}

	.tweet {
		margin: 20px auto;
		color: $grey;
			
		@media #{md('medium')} {
			margin: 40px auto;
			width: 90%;
		}

		&:before {
			content:'';
			position: absolute;
			top: -20px;
			width: 100%;
			height: 1px; 
			background: rgba($grey-dark, 0.2);

			@media #{md('medium')} {
				left: -5%;
				height: 100%;
				width: 1px;
				top: 0;
				margin-left: -16px;
			}
		}

		&.first {
			&:before {
				display: none;
			}
		}

		&:after {
			content:'';
			display: table;
			clear: both;
		}

		.tweet-content {
			//font-family: $font-family-serif;

			a {
				color: $red;
				&:hover {
					color: lighten($red, 20%);
				}
			}
		}

		.tweet-date {
			font-size: 1.1em;
			padding-top: 20px;
			display: block;
			width: 60%;
			float: left;
		}

		.tweet-actions {
			width: 40%;
			float: right;
			padding-top: 20px;
			text-align: right;

			.icon {
				* {
					transition: all 0.4s ease;
				}
			}

			a {
				display: inline-block;
				margin: 0 7px;

				&:hover{
					.icon{
						*{
							fill: rgba($grey-dark, 0.9);
						}
					}
				}
			}
		}
	}
}