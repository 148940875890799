/*------------------------------------*\
		Header
\*------------------------------------*/
.container-header {
	z-index: z('goku');
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	transition: all 0.5s ease;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;

	@media #{md('medium')} {
		position: absolute;
		
		.no-transition & {
			transition: none;
		}

		.fixed-menu & {
			position: fixed;
			transform: translateY(-130%);
		}

		.show-menu & {
			transform: translateY(0%);
		}
	}
}
.main-header{
	line-height: 50px;
	height: 50px;
	background: $grey-dark;
	color: #FFF;
	z-index: z('dropdown');
	box-shadow: 0 2px 5px rgba(darken($grey-dark, 10%), 0.2);
		
	@media #{md('medium')} {
		line-height: 80px;
		height: 80px;
	}

	.main-logo {
		top: 5px;
		z-index: z('goku', 1);

		@media #{md('medium')} {
			top: 14px;
			display: inline-block;
			vertical-align: top;
			z-index: z('default');
		}
	}

	.block-search {
		//overflow: hidden;
		width: 100%;
		padding: 10px 0;
		padding-left: 65px;
		line-height: 50px;

		@media #{md('medium')} {
			transition: width 0.9s $in-out-expo;
			transform-origin: right center;
			padding: 0;
			width: 40px;
			position: absolute;
			right: 0;
			top: 0;
			line-height: 80px;
		}

		.open-search & {
			@media #{md('medium')} {
				width: calc(100% - 40px);
			}
		}

		form {
			display: block;
			width: 100%;
			//overflow: hidden;
			//transform: translateX(100%);
			
			.open-search & {
				@media #{md('medium')} {
					padding-right: 55px;
				}
			}
		}

		.trigger-close-search{
			position: absolute;
			top: 50%;
			right: 20px;
			opacity: 0;
			transform: translateY(-50%);
			transition: all 0.4s ease 0;

			.open-search & {
				@media #{md('medium')} {
					transition-delay: 0.5s;
					opacity: 1;
				}
			}
		}

		.trigger-search {
			background: none;
			border: none;
			display: inline-block;
			position: absolute;
			top: 0;
			cursor: pointer;
			z-index: z('default');
			left: 0;
				
			@media #{md('medium')} {
				padding: 0 10px;
			}

			.icon {
				pointer-events: none;
				* {
					transition: all 0.4s ease;
				}
			}

			&:hover {
				.icon {
					* {
						fill: #FFF;
					}
				}
			}
		}

		input {
			width: 100%;
			line-height: 50px;
			height: 49px;
			background: none;
			display: block;
			border: none;
			color: #FFF;
			font-size: 1.33em;
			padding-left: 35px;
			background: $grey-dark;
			padding-right: 10px;
			
			@media #{md('medium')} {
				line-height: 80px;
				height: 79px;
				font-size: 2em;
				padding-left: 55px;
				padding-right: 0;
			}
		}
	}

	.toggle-menu {
		width: 24px;
		height: 24px;
		display: block;
		position: absolute;
		top: 13px;
		right: 15px;
	
		@media #{md('medium')} {
			display: none;
		}

		span {
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 2px;
			background: #FFF;
			transition: background-color 0s 0.15s linear;

			&:before,
			&:after {
				content:'';
				width: 100%;
				height: 2px;
				display: block;
				position: absolute;
				background: #FFF;
				left: 0;
			}

			&:before{
				top: 10px;
				transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                    transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
			}

			&:after {
				top: 20px;
				transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                    transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
			}

			.open-menu & {
				transition-delay: 0.32s;
				background-color: transparent;

				&::before {
					top: 0;
					transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
					          transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
					transform: translate3d(0, 12px, 0) rotate(45deg);
				}

				&::after {
					top: 0;
					transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
					          transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
					transform: translate3d(0, 12px, 0) rotate(-45deg);
				}
			}
		}
	}
}

.main-logo {
	display: block;
	text-indent: -9999px;
	overflow: hidden;
	background: transparent url(#{$svg-dir}logo.svg) center center no-repeat;
	background-size: contain;
	width: 156px;
	height: 83px;
	transition: opacity 0.3s ease;

	@media #{md('medium')} {
		width: 227px;
		height: 120px;
	}
}

.main-nav {
	//font-weight: 700;
	display: inline-block;
	vertical-align: top;
	width: 100%;
	padding-bottom: 30px;
	position: absolute;
	background: $grey-dark;
	top: 50px;
	left: 0;
	z-index: z('goku');
	padding-top: 30px;
	opacity: 0;
	visibility: hidden;
	transform: translateY(-40px);
	transition: all 0.4s $in-out-expo;

	.open-menu & {
		opacity: 1;
		visibility: visible;
		transform: translateY(0px);
	}

	@media #{md('medium')} {
		width: calc(100% - 240px);
		opacity: 1;
		visibility: visible;
		transform: translateY(0px);
		z-index: z('default');
		position: relative;
		padding-top: 0;
		padding-bottom: 0;
		top: auto;
		background: transparent;
	}
	@media #{md('large')} {
		padding-left: 20px;
	}

	ul {
		display: block;
		font-size: 1.33em;
		margin: auto;
		list-style: none;

		li {
			display: block;

			@media #{md('medium')} {
				display: inline-block;
				vertical-align: top;
				padding: 0 15px;
				transition: opacity 0.4s ease;

				@for $i from 4 through 1 {
					&:nth-child(#{$i}) {
						transition-delay: #{($i)*0.12}s
					}

					.open-search &:nth-child(#{$i}) {
						transition-delay: #{(4-$i)*0.15}s
					}
				}
			}

			@media #{md('large')} {
				padding: 0 20px;
			}

			.open-search & {
				opacity: 0;
			}

			a:not(.main-logo) {
				display: block;
				color: $grey;
				padding: 10px 0;
				padding-left: 65px;

				&:before {
					content:'';
					left: 65px;
					right: 0;
					position: absolute;
					height: 1px;
					background: #FFF;
					opacity: 0.2;
					bottom: 0;
				
					@media #{md('medium')} {
						display: none;
					}
				}

				@media #{md('medium')} {
					padding: 0 10px;
				}

				&:hover {
					color: #FFF;
				}
			}

			&.active {
				a:not(.main-logo) {
					color: #FFF;
					font-weight: 700;

					&:after {
						content:'';
						position: absolute;
						background: $red;

						height: 40px;
						left: 0;
						width: 2px;
						top: 50%;
						margin-top: -20px;

						@media #{md('medium')} {
							left: 0;
							width: 100%;
							top: 100%;
							margin-top: -2px;
							height: 4px;
						}
					}
				}
			}
		}
	}
}

.top-header {
	line-height: 30px;
	height: 30px;
	width: 100%;
	background: $black;
	color: $grey;
	text-align: right;

	.social-network {
		display: inline-block;
		padding: 0 40px;

		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			text-transform: uppercase;
			
			li {
				display: inline-block;

				a {
					top: -1px;
					display: block;
					padding: 0 2px;

					@media #{md('medium')} {
						padding: 0 6px;
					}

					.icon {
						transform: scale(0.65);

						@media #{md('medium')} {
							transform: scale(1);
						}
						* {
							transition: all 0.3s ease;
						}
					}

					&:hover {
						.icon {
							* {
								fill: #FFF;
							}
						}
					}
				}
			}
		}

	}

	.selector-lang {
		display: inline-block;

		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			text-transform: uppercase;
			
			li {
				display: inline-block;
				padding: 0 6px;
				padding-right: 4px;
				line-height: 1em;
				border-left: 1px solid $grey;

				&:first-child {
					border-left: 0;
				}

				a {
					&:hover {
						color: #FFF;
					}
				}

				&.active {
					color: #FFF;
					font-weight: 700;
				}
			}
		}
	}
}