.home {
	.hero {
		background-color: transparent;
	}
}
.section-news {
	background: #f3f3f2;
	padding-bottom: 10px;

	@media #{md('medium')} {
		margin-top: -40px;
		padding-bottom: 0;
		z-index: z('default', 5);
		background-color: transparent;
	}

	.link{
		color: $red;
		margin-top: 15px;
		margin-bottom: 15px;
		padding-left: 0;
		line-height: $line-height;

		.icon {
			position: relative;
			top: 0;
			left: 0;
			margin-top: 0;

			* {
				fill: $red;
			}
		}
		@include links($grey-dark);
	}
}

.block-communications {
	background: #f3f3f2;

	.block-title {
		color: $grey;
	}

	.carousel-communications {
		@media #{md('medium')} {
			margin-top: 20px;
			margin-bottom: 20px;
		}

		.flickity-prev-next-button {
			width: 30px;
			height: 30px;
			background: none;
			bottom: 100%;
			transform: none;
			right: 5px;
			left: auto;
			top: auto;
			margin-bottom: 30px;
			opacity: 0.6;
			transition: all 0.4s ease;
			display: none;
			transform-origin: right center;

			@media #{md('medium')} {
				display: block;
			}

			svg {
				width: 20px;
				height: 30px;
				left: 50%;
				top: 50%;
				margin-left: -10px;
				margin-top: -15px;
			}

			&.previous {
				right: 35px;
				transform-origin: left center;
			}

			&:hover {
				opacity: 1;
			}

			&:active {
				transform: scaleY(0.6);
			}
		}
	}
}

.section-directory {
	padding-top: 15px;
	padding-bottom: 30px;

	@media #{md('medium')} {
		padding-top: 30px;
		padding-bottom: 80px;
	}

	h3 {
		font-size: 1.33em;
		margin-top: 0.7em;
		margin-bottom: 0.7em;
		color: $red;
		padding: 0 16px;
			
		@media #{md('medium')} {
			padding: 0;
			margin-top: 1.7em;
			margin-bottom: 1.7em;
		}
	}

	.list-directories {
		list-style: none;
		padding: 0;
		margin-top: 0.5em;
		margin-bottom: 0.5em;
		padding-bottom: 1em;
		border-bottom: 2px solid $grey-light;

		@media #{md('medium')} {
			border-bottom: none;
			padding: 0;
			margin-top: 1em;
			margin-bottom: 1em;
		}

		li {
			display: block;
		
			@media #{md('medium')} {
				margin-bottom: -2px;
			}

			a {
				display: block;
				padding: 0 16px;
				padding-right: 50px;
				transition: all 0.4s ease;
				line-height: 50px;

				@media #{md('medium')} {
					line-height: 70px;
					border-top: 2px solid $grey-light;
					border-bottom: 2px solid $grey-light;
				}

				&:before {
					content:'';
					width: 100%;
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					background: $grey-light;
					transform: scaleX(0);
					transform-origin: left center;
					transition: all 0.4s $in-out-expo;
				}

				span {
					display: inline-block;
					vertical-align: middle;
					line-height: 1.2em;
				}

				.icon {
					position: absolute;
					right: 16px;
					top: 50%;
					margin-top: -6px;
					opacity: 0;
					transform: translateX(-20px);
					transition: all 0.4s $in-out-expo;

					* {
						fill: $grey-dark;
					}
				}

				&:hover {
					//font-weight: 700;

					&:before {
						transform: scaleX(1);
					}

					.icon {
						opacity: 1;
						transform: translateX(0);
						transition-delay: 0.15s;
					}
				}
			}
		}
	}
}

.section-zoom {
	background: $grey-dark;
	color: #FFF;

	.btn {
		margin-bottom: 30px;
	}

	.bg-agenda {
		width: 45%;
		right: 0;
		top: 70px;
		bottom: -80px;
		content:'';
		position: absolute;
		background: #FFF;
		z-index: z('default', 1);
		display: none;

		@media #{md('medium')} {
			display: block;
		}
		@media #{md('large')} {
			width: 42%;
		}
	}

	.list-zoom {
		margin: 20px 0;
	}

	.zoom-item {
		margin-top: 10px;

		@media #{md('medium')} {
			margin-top: 30px;
		}

		.zoom-img {
			display: block;
			width: 100%;
			margin-bottom: 5px;

			img {
				display: block;
				max-width: 100%;

				&.is-loaded {
					width: 100%;
				}
			}

			a {
				display: block;
				min-height: 60px;
				width: 100%;

				@include hover-img();
			}
		}

		.zoom-cat {
			text-transform: uppercase;
			padding: 8px 0;
			text-transform: uppercase;
			color: $red;
		}

		.zoom-title {
			font-size: 1.33em;
			margin-bottom: 1em;
			padding: 8px 0;
			font-weight: 400;
			margin-top: 0;
			min-height: #{$line-height * 2}em;

			a{
				display: block;

				&:hover{
					opacity: 0.6;
				}
			}
		}
	}
}

.block-agenda {
	color: $grey-dark;
	padding-top: 0px;
	z-index: z('default', 2);
	background: #FFF;

	@media #{md('medium')} {
		padding-top: 70px;
		background: none;
	}

	.list-agenda {
		padding-top: 15px;
		padding-bottom: 15px;

		@media #{md('medium')} {
			padding-bottom: 25px;
		}
	}

	.agenda-item {
		padding: 25px 0;
		border-top: 1px solid rgba($grey-dark, 0.2);

		@media #{md('medium')} {
			padding: 35px 0;
		}

		&:after{
			clear: both;
			display: table;
			content:'';
		}

		&:first-child {
			border-top: 0;
		}

		.agenda-date {
			width: 65px;
			color: $red;
			text-align: center;
			line-height: 1.1em;
			text-transform: uppercase;
			margin-right: 15px;
			display: inline-block;
			vertical-align: middle;

			span {
				display: block;
				font-size: 4em;
				line-height: 0.8em;
				padding-bottom: 5px;
				
				@media #{md('small')} {
					font-size: 4em;
				}
			}
			
			@media #{md('small')} {
				margin-right: 15px;
				width: 75px;
				float: left;
			}
		}

		.agenda-title {
			font-size: 1.2em;
			text-transform: uppercase;
			margin-top: 0;
			margin-bottom: 0.5em;
			display: inline-block;
			vertical-align: middle;
			width: calc(100% - 90px);
			
			@media #{md('small')} {
				display: block;
				width: auto;
				margin-left: 95px;
			}
		}

		.agenda-desc {
			transition: all 0.3s ease;
			display: none;

			@media #{md('small')} {
				display: block;
				margin-left: 95px;
			}
		}

		.agenda-link {
			position: absolute;
			top: 0;
			left: 0;
			z-index: z('default');
			width: 100%;
			height: 100%;
		}

		.icon {
			transition: all 0.3s $in-out-expo;
			position: absolute;
			top: 100%;
			right: 0;
			display: none;
			transform: translateX(-15px);
			margin-top: -30px;
			opacity: 0;
			pointer-events: none;
			visibility: hidden;

			* {
				transition: all 0.3s ease;
				fill: $red;
			}

			@media #{md('medium')} {
				display: block;
			}
		}

		&:hover {
			.agenda-title,
			.agenda-desc {
				color: $red;
			}

			.icon {
				opacity: 1;
				visibility: visible;
				transform: translateX(0);
			}
		}
	}
}

.section-services {
	background: transparent url(#{$img-dir}bg-services.jpg) center center no-repeat;
	background-size: cover;
	background-attachment: fixed;
	color: #FFF;
	padding-bottom: 40px;

	&.no-fix {
		background-attachment: initial;
	}

	@media #{md('medium')} {
		padding-top: 195px;
		padding-bottom: 130px;
	}

	&:before {
		position: absolute;
		content:'';
		top: -40px;
		left: 50%;
		height: 260px;
		width: 1px;
		background: #FFF;
		display: none;

		@media #{md('medium')} {
			display: block;
		}
	}

	.list-services {
		padding-top: 20px;
		padding-bottom: 20px;
		padding-right: 20px;
		padding-left: 20px;
		background: #FFF;
		margin: auto;
		width: 96%;
		margin-top: 10px;

		@media #{md('medium')} {
			width: auto;
			padding-top: 50px;
			margin-top: 30px;
			padding-bottom: 50px;
		}

		.service-item {
			border-bottom: 1px solid rgba($grey-dark, 0.2);

			@media #{md('medium')} {
				border-bottom: 0;
			}

			&:first-child {
				&:before {
					display: none;
				}
			}
			&:nth-child(3) {
				border-bottom: none;

				&:before {
					display: none;
					@media #{md('medium')} {
						display: block;
					}
				}
			}
			&:nth-child(4) {
				border-bottom: none;
			}

			&:before {
				left: 0;
				height: 100%;
				width: 1px;
				content:'';
				background: rgba($grey-dark, 0.2);
				top: 0;
				position: absolute;
			}

			a {
				display: block;
				text-align: center;
				color: $grey;
				width: 100%;
				padding: 24px 0;

				.icon {
					margin-bottom: 30px;
					* {
						transition: all 0.4s ease;
					}
				}

				span {
					font-size: 1.2em;
					display: block;

					@media #{md('medium')} {
						font-size: 1.6em;
					}
				}

				&:hover {
					color: $grey-dark;

					.icon {
						* {
							fill: $grey-dark;
						}
					}
				}
			}
		}
	}
}

.section-more-infos {
	padding-top: 10px;
	padding-bottom: 30px;

	@media #{md('medium')} {
		padding-top: 60px;
		padding-bottom: 70px;
	}

	.list-infos {
		font-size: 0;
		text-align: center;

		@media #{md('medium')} {
			padding: 20px 0;
		}

		.grid__col-xxs--6 {
			float: none;
			display: inline-block;
			font-size: $font-size;
		}
	}
}

.info-cat-item {
	display: block;
	width: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	margin: 8px 0;
	height: 205px;
	text-align: left;
	overflow: hidden;

	&.small {
		height: 120px;
	}

	@media #{md("smaller")} {
		margin-top: $grid-gutter--smaller / 2;
		margin-bottom: $grid-gutter--smaller / 2;
	}

	@media #{md("small")} {
		margin-top: $grid-gutter--small / 2;
		margin-bottom: $grid-gutter--small / 2;
	}

	@media #{md("medium")} {
		margin-top: $grid-gutter--medium / 2;
		margin-bottom: $grid-gutter--medium / 2;
		height: 420px;

		&.small {
			height: 160px;
		}
	}

	@media #{md("large")} {
		margin-top: $grid-gutter--large / 2;
		margin-bottom: $grid-gutter--large / 2;
	}

	@media #{md("larger")} {
		margin-top: $grid-gutter--larger / 2;
		margin-bottom: $grid-gutter--larger / 2;
	}

	&:before {
		content:'';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		background: $grey-dark;
		opacity: 0.5;
	}

	a {
		display: block;
		height: 100%;
		width: 100%;
		font-size: 1.2em;
		line-height: 1.1em;
		color: #FFF;
		letter-spacing: 0.01em;
		font-weight: 700;
		overflow: hidden;
		padding: 25px 15px;

		@media #{md('medium')} {
			padding: 70px 20px;
			font-size: 1.73em;
		}

		&:before {
			content:'';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			position: absolute;
			background: $red;
			opacity: 0.9;
			transform: translateX(-100%);
			transition: all 0.5s $in-out-expo;
		}

		.icon {
			display: block;
			margin-top: 20px;
			opacity: 0;
			transform: translateX(-15px);
			transition: all 0.4s $in-out-expo;
		}

		&:hover {
			&:before {
				transform: translateX(0%);
			}

			.icon {
				opacity: 1;
				transform: translateX(0);
				transition-delay: 0.1s;
			}
		}
	}

	&.small {
		text-align: center;

		a {
			display: table;
			width: 100%;
			padding: 0 20px;

			span {
				display: table-cell;
				vertical-align: middle;
				width: 100%;
			}

			.icon {
				left: 50%;
				margin-left: -18px;
				position: absolute;
				bottom: 30px;
			}
		}
	}
}