/*------------------------------------*\
		Pictogrammes
\*------------------------------------*/

.icon {
	display: inline-block;
	vertical-align: middle;
	transition: all 0.4s ease;

	svg {
		display: block;
	}
}

.icon_loupe,
.svg_loupe {
	width: 20px;
	height: 20px;

	* {
		fill: $grey;
	}
}

.icon_repartition,
.svg_repartition {
	width: 20px;
	height: 20px;
	top: -1px;

	* {
		fill: $grey;
	}
}

.icon_delegation,
.svg_delegation {
	width: 20px;
	height: 20px;
	top: -2px;

	* {
		fill: $grey;
	}
}

.icon_ministere,
.svg_ministere {
	width: 31px;
	height: 40px;
	top: -1px;

	* {
		fill: $red;
	}
}

.icon_tribunal,
.svg_tribunal {
	width: 40px;
	height: 40px;
	top: -1px;

	* {
		fill: $red;
	}
}

.icon_flickr,
.svg_flickr {
	width: 28px;
	height: 12px;

	@media #{md('medium')} {
		width: 17px;
		height: 7px;
	}

	* {
		fill: $grey;
	}
}

.icon_fb,
.svg_fb {
	width: 12px;
	height: 25px;

	@media #{md('medium')} {
		width: 8px;
		height: 15px;
	}

	* {
		fill: $grey;
	}
}

.icon_tw,
.svg_tw {
	width: 31px;
	height: 25px;

	@media #{md('medium')} {
		width: 19px;
		height: 15px;
	}

	* {
		fill: $grey;
	}
}

.icon_insta,
.svg_insta {
	width: 26px;
	height: 25px;

	@media #{md('medium')} {
		width: 16px;
		height: 15px;
	}

	* {
		fill: $grey;
	}
}

.icon_arrow-left,
.svg_arrow-left,
.icon_arrow-right,
.svg_arrow-right {
	width: 37px;
	height: 12px;

	* {
		fill: #FFF;
	}
}

.icon_arrow-right-xs,
.svg_arrow-right-xs {
	width: 27px;
	height: 12px;

	* {
		fill: #FFF;
	}
}

.icon_arrow-down,
.svg_arrow-down {
	width: 12px;
	height: 37px;

	* {
		fill: #FFF;
	}
}

.icon_directory,
.svg_directory {
	width: 34px;
	height: 40px;
	top: -1px;

	* {
		fill: $red;
	}
}

.icon_book,
.svg_book {
	width: 40px;
	height: 40px;
	top: -1px;

	* {
		fill: $red;
	}
}

.icon_agenda,
.svg_agenda {
	width: 43px;
	height: 40px;
	top: -1px;

	* {
		fill: $red;
	}
}

.icon_thematique,
.svg_thematique {
	width: 40px;
	height: 40px;
	top: -1px;

	* {
		fill: $red;
	}
}

.icon_time,
.svg_time {
	width: 44px;
	height: 40px;
	top: -1px;

	* {
		fill: $red;
	}
}

.icon_backpack,
.svg_backpack {
	width: 48px;
	height: 40px;
	top: -2px;

	* {
		fill: $red;
	}
}

.icon_guichet,
.svg_guichet {
	width: 40px;
	height: 38px;
	top: -1px;

	* {
		fill: $red;
	}
}

.icon_road-sign,
.svg_road-sign {
	width: 47px;
	height: 40px;
	top: -1px;

	* {
		fill: $red;
	}
}

.icon_administration,
.svg_administration {
	width: 58px;
	height: 60px;

	* {
		fill: $grey;
	}
}

.icon_gouvernement,
.svg_gouvernement {
	width: 89px;
	height: 60px;

	* {
		fill: $grey;
	}
}

.icon_parlement,
.svg_parlement {
	width: 65px;
	height: 60px;

	* {
		fill: $grey;
	}
}

.icon_legislation,
.svg_legislation {
	width: 60px;
	height: 60px;

	* {
		fill: $grey;
	}
}

.icon_tableau,
.svg_tableau {
	width: 62px;
	height: 50px;

	* {
		fill: $grey;
	}
}

.icon_registre,
.svg_registre {
	width: 53px;
	height: 50px;

	* {
		fill: $grey;
	}
}

.icon_justice,
.svg_justice {
	width: 60px;
	height: 60px;

	* {
		fill: $grey;
	}
}

.icon_consultations,
.svg_consultations {
	width: 51px;
	height: 60px;

	* {
		fill: $grey;
	}
}

.icon_organigramme,
.svg_organigramme {
	width: 66px;
	height: 48px;

	* {
		fill: $grey;
	}
}

.icon_annuaire,
.svg_annuaire {
	width: 51px;
	height: 60px;

	* {
		fill: $grey;
	}
}

.icon_bulletin,
.svg_bulletin {
	width: 58px;
	height: 50px;

	* {
		fill: $grey;
	}
}

.icon_police,
.svg_police {
	width: 43px;
	height: 60px;

	* {
		fill: $grey;
	}
}

.icon_historique,
.svg_historique {
	width: 40px;
	height: 60px;

	* {
		fill: $grey;
	}
}

.icon_reply,
.svg_reply {
	width: 16px;
	height: 12px;

	* {
		fill: $grey;
	}
}

.icon_retweet,
.svg_retweet {
	width: 22px;
	height: 12px;

	* {
		fill: $grey;
	}
}

.icon_hearth,
.svg_hearth {
	width: 14px;
	height: 12px;

	* {
		fill: $grey;
	}
}

.icon_pdf,
.svg_pdf {
	width: 16px;
	height: 20px;

	* {
		fill: $red;
	}
}

.icon_tel,
.svg_tel {
	width: 16px;
	height: 16px;

	* {
		fill: $red;
	}
}

.icon_fax,
.svg_fax {
	width: 16px;
	height: 13px;

	* {
		fill: $red;
	}
}

.icon_calendar,
.svg_calendar {
	width: 22px;
	height: 20px;
	top: -1px;

	* {
		fill: $red;
	}
}

.icon_enveloppe,
.svg_enveloppe {
	width: 16px;
	height: 9px;

	* {
		fill: $red;
	}
}

.icon_check,
.svg_check {
	width: 11px;
	height: 8px;

	* {
		fill: #FFF;
	}
}

.icon_share,
.svg_share {
	width: 22px;
	height: 29px;

	* {
		fill: $grey-dark;
	}
}

/*------------------------------------*\
		Tree of life
\*------------------------------------*/
.icon_naissance,
.svg_naissance {
	width: 60px;
	height: 43px;

	* {
		fill: $grey;
	}
}
.icon_ecole,
.svg_ecole {
	width: 50px;
	height: 90px;

	* {
		fill: $grey;
	}
}
.icon_travail,
.svg_travail {
	width: 86px;
	height: 109px;

	* {
		fill: $grey;
	}
}
.icon_union,
.svg_union {
	width: 64px;
	height: 126px;

	* {
		fill: $grey;
	}
}
.icon_famille,
.svg_famille {
	width: 109px;
	height: 111px;

	* {
		fill: $grey;
	}
}
.icon_retraite,
.svg_retraite {
	width: 45px;
	height: 100px;

	* {
		fill: $grey;
	}
}
.icon_deces,
.svg_deces {
	width: 125px;
	height: 51px;

	* {
		fill: $grey;
	}
}

/*------------------------------------*\
		Icon cross
\*------------------------------------*/
.icon_cross {
	width: 26px;
	height: 26px;
	display: inline-block;
	transform: rotate(45deg);
	vertical-align: middle;

	i {
		position: absolute;
		width: 2px;
		height: 100%;
		margin-left: -1px;
		top: 0;
		left: 50%;
		background: $grey;
		overflow: hidden;

		&:first-child {
			transform: rotate(-90deg);
		}

		&:after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			content:'';
			background: #FFF;
			transition: all 0.6s $in-out-expo;
			transform: translateY(-100%) translateZ(0);
		}
	}

	a &:hover,
	&:hover {
		i {
			&:after {
				transform: translateY(0%) translateZ(0);
			}
		}
	}
}