/*------------------------------------*\
		Buttons
\*------------------------------------*/

.btn {
	cursor: pointer;
	display: inline-block;
	background: #FFF;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 0.1em;
	color: $grey-dark;
	padding: 18px 20px;
	border: none;
	line-height: 1.38em;
	overflow: hidden;
	transform: translateZ(0);
	will-change: color;
	transition: all 0.8s $out-quint;
	backface-visibility: hidden;

	&:before {
		content:'';
		transform: skewY(-15deg) translateY(150%) scaleX(0.6) translateZ(0);
		width: 100%;
		height: 200%;
		background: $red;
		position: absolute;
		top: -50%;
		left: 0;
		will-change: transform;
		transition: all 0.9s $out-quint;
		backface-visibility: hidden;
	}

	&:hover {
		color: #FFF !important;
		
		&:before {
			transform: skewY(-10deg) translateY(0%) scaleX(1.25) translateZ(0);
		}
	}

	&.btn-grey {
		color: #FFF;
		background: $grey-dark;
	}

	&.btn-red {
		color: #FFF;
		background: $red;

		&:before {
			background: $grey-dark;
		}
	}
}

.link {
	display: block;
	padding-left: 50px;
	line-height: 1.2em;
	margin: 12px 0;

	.icon {
		position: absolute;
		top: 0.5em;
		left: 0;
		margin-top: -5px;

		* {
			fill: $grey-dark;
			transition: all 0.2s ease;
		}
	}

	&:hover {
		.icon {
			animation: hoverLink 0.5s forwards ease;

			* {
				fill: $red;
			}
		}
	}

	@keyframes hoverLink {
		0% { transform : translateX(0); }
		50% { transform : translateX(10px); }
		100% { transform : translateX(0); }
	}
}

.link-pdf {
	display: inline-block;
	margin: 8px 0;
	color: $grey;
	transition: all 0.4s ease;

	strong {
		color: $grey-dark;
	}

	.icon {
		margin-right: 5px;
		transition: all 0.4s ease;
	}

	&:hover {
		.icon {
			margin-right: 10px;
		}
	}
}

.link-red {
	text-transform: uppercase;
	font-weight: 700;
	font-size: 0.86em;
	letter-spacing: 0.1em;
	line-height: 1.4em;
	color: $red;

	.icon {
		transition: all 0.4s $in-out-quart;
		top: -1px;
		margin-left: 5px;
		
		* {
			fill: $red;
		}
	}

	&:hover {
		.icon {
			transform: translateX(15px);
		}
	}
}