.breadcrumb {
	margin: 0;
	padding: 14px 0;
	margin-bottom: 10px;
	list-style: none;
	z-index: z('default');
	border-bottom: 1px solid #cacac9;
	width: 100%;
	overflow-y: auto;
	white-space:nowrap;
	-webkit-overflow-scrolling: touch; 

	@media #{md('small')} {
		font-size: 1.2em;
		margin-bottom: 20px;
		padding: 20px 0;
	}

	li {
		display: inline-block;
		padding-left: 18px;
		padding-right: 10px;
		color: $grey;
	
		@media #{md('small')} {
			padding-left: 26px;
			padding-right: 20px;
		}

		&:before {
			position: absolute;
			content:'';
			background: url(#{$svg-dir}breadcrumb.svg) center center no-repeat;
			background-size: contain;
			width: 7px;
			height: 14px;
			left: 0;
			top: 50%;
			margin-top: -7px;
		}

		&:first-child {
			padding-left: 0;

			&:before {
				display: none;
			}
		}

		&.current {
			font-weight: 700;
			color: $grey-dark;
		}
	}

	& + .link {
		position: absolute;
		top: 23px;
		z-index: z('default');
		font-size: 0.86em;
		right: 30px;
		margin: 0;
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 0.1em;
		line-height: $line-height;
		display: none;

		@media #{md('medium')} {
			display: block;
		}

		.icon {
			margin-top: -3px;
		}


		&:not(.back) {
			color: $red;
			padding-left: 0;

			.icon {
				position: relative;
				top: -2px;
				left: 0;
				margin-top: 0;
				margin-left: 4px;

				* {
					fill: $red;
				}
			}
		}
	}
}